import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { deleteMethod, getMethodAuth, postMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import Pagination from '../utils/Pagination'

const AutoApproveDeposit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [refr, setRefr] = useState(true);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const navigate = useNavigate();
    const [paymentProviderId, setPaymentProviderId] = useState("");
    const [paymentProviderIdValidate, setPaymentProviderIdValidate] = useState("");
    const [amount, setAmount] = useState("");
    const [amountValidate, setAmountValidate] = useState("");
    const [transitionNo, setTransitionNo] = useState("");
    const [transitionNoValidate, setTransitionNoValidate] = useState("");
    const [editData, setEditData] = useState('');
    const [searchPaymentProviderId, setSearchPaymentProviderId] = useState("");
    const [searchAmount, setSearchAmount] = useState("");
    const [searchTransitionNo, setSearchTransitionNo] = useState("");
    const [paymentProviders, setPaymentProviders] = useState([]);
    const [totalPage,setTotalPage] = useState(1);
    const [from,setFrom] = useState(1);
    const [page,setPage] = useState(1);

    // fetch initial data 
    useEffect(() => {
        let token = JSON.parse(localStorage.getItem('admin_auth'));
        (async () => {
            if (token) {
                let res = await getMethodAuth(`/okie/auto-deposits?payment_provider_id=${searchPaymentProviderId}&amount=${searchAmount}&transaction_no=${searchTransitionNo}&page=${page}`, token.token);
                if (res) {
                    setLoading(false);
                    if (res.status === "success") {
                        if (res?.data) {
                            setData(res?.data);
                        };
                        if(res.meta){
                            if(res.meta.from){
                                setFrom(res.meta.from)
                            }
                            if(res.meta.last_page){
                                setTotalPage(res.meta.last_page);
                            };
                        };
                    };
                } else {
                    setLoading(false);
                };
            }
            if (token) {
                const response = await getMethodAuth(`/payment-providers`, token.token);
                if (response) {
                    setLoading(false);
                    if (response.status === "success") {
                        if (response?.data) {
                            setPaymentProviders(response?.data.filter(d => d.status === 'active'));
                        };
                    };
                } else {
                    setLoading(false);
                };
            }
        })();
    }, [refr, page]);

    const editOpenModal = () => {
        setEdit(!edit);
    };

    const createOpenModal = () => {
        setCreate(!create);
    };
    // for input handler 
    const paymentProviderIdHandler = e => {
        setPaymentProviderId(e.target.value)
        setPaymentProviderIdValidate("");
    }
    const amountHandler = e => {
        setAmount(e.target.value)
        setAmountValidate("");
    }
    const transitionNoHandler = e => {
        setTransitionNo(e.target.value)
        setTransitionNoValidate("");
    }
    const searchIdHandler = e => {
        setSearchPaymentProviderId(e.target.value);
    }
    const searchAmountHanlder = e => {
        setSearchAmount(e.target.value);
    }
    const searchTransitionNoHandler = e => {
        setSearchTransitionNo(e.target.value);
    }
    // for search submit handler
    const searchSubmitHandler = () => {
        setRefr(!refr);
    }
    // for create handler 
    const createSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            payment_provider_id: paymentProviderId,
            amount: amount,
            transaction_no: transitionNo
        }
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        const response = await postMethod(`/okie/auto-deposits`, data, token.token);
        if (response) {
            setLoading(false);
            if (response.status === "success") {
                setCreate(false);
                setRefr(!refr);
                setPaymentProviderId("");
                setAmount("");
                setTransitionNo("");
            } else {
                if (response.message.payment_provider_id) {
                    setPaymentProviderIdValidate(response.message.payment_provider_id[0])
                }
                if (response.message.amount) {
                    setAmountValidate(response.message.amount[0])
                }
                if (response.message.transaction_no) {
                    setTransitionNoValidate(response.message.transaction_no[0])
                }
            }
        }
    };
    useEffect(() => {
        setPaymentProviderId(editData.payment_provider_name);
        setAmount(editData.amount);
        setTransitionNo(editData.transaction_no);
    }, [editData]);
    // for edit handler 
    const editSubmitHandler = async (e) => {
        e.preventDefault();
        if (editData) {
            setLoading(true);
            const data = {
                amount: amount,
                transaction_no: transitionNo,
            }
            const token = JSON.parse(localStorage.getItem('admin_auth'));
            const response = await postMethod(`/okie/auto-deposits/${editData.id}/update`, data, token.token);
            if (response.status === "success") {
                setEdit(false);
                setRefr(!refr);
                setPaymentProviderId("");
                setAmount("");
                setTransitionNo("");
            } else {
                if (response.message.amount) {
                    setAmountValidate(response.message.amount[0])
                }
                if (response.message.transaction_no) {
                    setTransitionNoValidate(response.message.transaction_no[0])
                }
            }
        };
    };
    // for delete handler 
    const deleteHandler = async (id) => {
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            width: 300,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (data.length > 1) {
                if (result.isConfirmed) {
                    setLoading(true);
                    (async () => {
                        if (token) {
                            const res = await deleteMethod(`/okie/auto-deposits/${id}/delete`, token.token);
                            if (res) {
                                setLoading(false);
                                if (res.message === "Unauthenticated.") {
                                    localStorage.removeItem('admin_auth');
                                    navigate("/admin/login")
                                }
                                if (res.status === "error") {
                                    SweetAlert.fire({
                                        icon: 'error',
                                        width: 300,
                                        title: 'Fail',
                                        text: res.message,
                                    })
                                }
                                if (res.status === "success") {
                                    SweetAlert.fire({
                                        title: 'Success!',
                                        text: "You has been saved.",
                                        icon: 'success',
                                        width: 300,
                                    });
                                    setRefr(!refr);
                                };
                                if (res.status_code === "403") {
                                    alert(res.message);
                                    localStorage.removeItem('admin_auth');
                                    navigate("/admin/login")
                                };
                            } else {
                                setLoading(false);
                            };
                        } else {
                            setLoading(false);
                        }
                    })();
                };
            } else {
                SweetAlert.fire({
                    icon: 'error',
                    width: 300,
                    title: "Fail!",
                    text: "There must be at least one banner image",
                })
            };
        });
    }
    return (
        <>
            <Layout>
                <Breadcrumbs title="Auto Approve Deposits" />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-0 pt-3'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={searchIdHandler}>
                                                        <option value="">Select Payment</option>
                                                        {
                                                            paymentProviders.length > 0 && paymentProviders.map(p =>
                                                                <option key={p.id} value={p.id}>{p.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input className="form-control" onChange={searchAmountHanlder} type="number" placeholder="Search Amount" />
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input className="form-control" onChange={searchTransitionNoHandler} type="number" placeholder="Search Transition No." />
                                                </Col>
                                                {/* <Col md="4 px-1 mb-2">
                                                    <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate}/>
                                                </Col> */}
                                                <Col md="4 px-1 mb-2">
                                                    <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading ? "Loading..." : "Submit"}</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardHeader className='py-2 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Payment</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Amount</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Transition No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Status</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Operator</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.length > 0 &&
                                                    data?.map((el, i) =>
                                                        <tr key={i} className="align-middle">
                                                            <th className='text-center' scope="row">{`${i + from}.`}</th>
                                                            <td className='text-center'>{el?.payment_provider_name}</td>
                                                            <td className='text-center'>{el?.amount}</td>
                                                            <td className='text-center'>{el?.transaction_no}</td>
                                                            <td className='text-center'>
                                                                <span className={`badge bg-${el.status === 'Approved' ? 'success' : el.status === 'Rejected' ? 'danger' : 'warning'}`}>
                                                                {el?.status}
                                                                </span>
                                                            </td>
                                                            <td className='text-center'>{el?.receive_name}</td>
                                                            <td className='text-center'>
                                                                <div>
                                                                    <span onClick={() => {
                                                                        editOpenModal();
                                                                        setEditData(el);
                                                                    }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                                    <span onClick={() => {
                                                                        deleteHandler(el.id);
                                                                    }}><i className="fa fa-trash" style={{ width: 35, fontSize: 18, padding: 11, color: '#dc3545', cursor: 'pointer' }}></i></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                            </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>
            {
                <Modal isOpen={create} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create Auto Approve Deposit
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Payment Provider ID</Label>
                                <Input type="select" name="select" className="form-control" onChange={paymentProviderIdHandler}>
                                    <option value="">Select Payment</option>
                                    {
                                        paymentProviders.length > 0 && paymentProviders.map(p =>
                                            <option key={p.id} value={p.id}>{p.name}</option>)
                                    }
                                </Input>
                                {
                                    paymentProviderIdValidate && <div className="invalid-feedback d-block">{paymentProviderIdValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Deposit Amount</Label>
                                <Input className="form-control" type="number" defaultValue={amount} placeholder="Deposit Amount" onChange={amountHandler} />
                                {
                                    amountValidate && <div className="invalid-feedback d-block">{amountValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Transition No.</Label>
                                <Input className="form-control" type="number" defaultValue={transitionNo} placeholder="Transition No." onChange={transitionNoHandler} />
                                {
                                    transitionNoValidate && <div className="invalid-feedback d-block">{transitionNoValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Cancel</Button>
                            <Button color="primary" type='submit'>Create</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {
                <Modal isOpen={edit} toggle={editOpenModal} centered>
                    <Form onSubmit={editSubmitHandler}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Auto Approve Deposit
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Payment</Label>
                                <Input className="form-control" type="text" disabled value={paymentProviderId} />
                                {
                                    paymentProviderIdValidate && <div className="invalid-feedback d-block">{paymentProviderIdValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Deposit Amount</Label>
                                <Input className="form-control" type="number" defaultValue={amount} placeholder="Deposit Amount" onChange={amountHandler} />
                                {
                                    amountValidate && <div className="invalid-feedback d-block">{amountValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Transition No.</Label>
                                <Input className="form-control" type="number" defaultValue={transitionNo} placeholder="Transition No." onChange={transitionNoHandler} />
                                {
                                    transitionNoValidate && <div className="invalid-feedback d-block">{transitionNoValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default AutoApproveDeposit
