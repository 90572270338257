import React, { useEffect, useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody ,Input, FormGroup, InputGroup, Button, Form, CardFooter, Label, InputGroupText} from 'reactstrap';
import Layout from './Layout';
import Loader from '../layout/loader/Loader';
import { getMethod, getMethodAuth, postMethod } from '../api';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';

const UserCreate = () => {
    const [loading,setLoading] = useState(false);
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [password,setPassword] = useState("");
    const [conPassword,setConPassword] = useState("");
    // const [bankAccountNumber,setBankAccountNumber] = useState("");
    // const [bankAccountName,setBankAccountName] = useState("");
    // const [bankName,setBankName] = useState("");
    const [verify,setVerify] = useState("");
    const [nameValidate,setNameValidate] = useState("");
    const [phoneValidate,setPhoneValidate] = useState("");
    const [passwordValidate, setPasswordValidate] = useState("");
    const [conPasswordValidate, setConPasswordValidate] = useState("");
    const [verifyValidate, setVerifyValidate] = useState("");
    // const [bankAccoutNumberValidate,setBankAccoutNumberValidate] = useState("");
    // const [bankAccoutNameValidate,setBankAccoutNameValidate] = useState("");
    // const [bankNameValidate,setBankNameValidate] = useState("");
    const [paymentType,setPaymentType] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [streamerId, setStreamerId] = useState('');
    const [streamers, setStreamers] = useState([]);
    const [authRole, setAuthRole] = useState('');
    const navigate = useNavigate();

    useEffect(()=>{
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        token && setAuthRole(token.user_role);
        (async ()=> {
        const resPaymentType = await getMethod(`/okie/payment-providers?status=active&sortColumn=id&sortDirection=asc&app_name_id=${process.env.REACT_APP_ID}`);
        if(resPaymentType){
            if(resPaymentType.status === "success"){
                if(resPaymentType.data){
                    setPaymentType(resPaymentType.data);
                };
            };
        };

        if (token.user_role === 'admin') {
            const resStreamer = await getMethodAuth(`/streamers`,token.token);
            if(resStreamer){
                if(resStreamer.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                };
                if(resStreamer.status === "success"){
                    if(resStreamer.data){
                        setStreamers(resStreamer.data)
                    };
                };
            };
        }
        })();
    },[]);

    const registerSubmitHandler = async e => {
        e.preventDefault();
        setLoading(true);
        const data = {
           name,phone,password,
           password_confirmation: conPassword,
           otp: verify,
        //    bank_name: bankName,
        //    bank_acc_name: bankAccountName,
        //    bank_acc_no: bankAccountNumber,
           merchant_id: '69kfph2640ee325b3244',
           role: userRole,
           streamer_id: Number(streamerId) === 0 ? null : Number(streamerId),
        };
        const res = await postMethod(`/okie/register`,data);
        console.log(data)
        if(res){
           setLoading(false);
           if(res.success){
            SweetAlert.fire({
                icon: 'success',
                width: 300,
                title: 'Success!',
                text: "Register Successful!"
             }).then(res => {
                if(res.isConfirmed){
                    setName("");
                    setPhone("");
                    setVerify("");
                    setPassword("");
                    setConPassword("");
                    // setBankAccountNumber("");
                    // setBankAccountName("");
                    // setBankName("");
                    setPasswordValidate("");
                    setPhoneValidate("");
                    setStreamerId("");
                    setUserRole("");
                };
             });
           }else{
              if(res.data.error){
               setPhoneValidate(res.data.error);
               setPasswordValidate(res.data.error);
              }
              if(res.data.name){
                setNameValidate(res.data.name[0]);
             };
              if(res.data.phone){
                 setPhoneValidate(res.data.phone[0]);
              };
              if(res.data.password){
                 setPasswordValidate(res.data.password[0]);
              };
              if(res.data.password_confirmation){
                setConPasswordValidate(res.data.password_confirmation[0]);
              };
            //   if(res.data.bank_name){
            //     setBankNameValidate(res.data.bank_name[0]);
            //   };
            //   if(res.data.bank_acc_no){
            //     setBankAccoutNumberValidate(res.data.bank_acc_no[0]);
            //   };
            //   if(res.data.bank_acc_name){
            //     setBankAccoutNameValidate(res.data.bank_acc_name[0]);
            //   };
              if(res.data.otp){
                setVerifyValidate(res.data.otp[0]);
              };
              if(typeof res.data.error === "string"){
                setVerifyValidate(res.data.error);
               }
           }
        }else{
          setLoading(false);
        };
     };
    
    const getOtp = async (e) => {
        if(phone.length > 5 && phone.length < 12){
          let res = await postMethod(`/getOTP?type=admin`, {phone,app_name_id: process.env.REACT_APP_ID});
          if(res.success){
             if(res.data){
                setVerify(res.data);
                setVerifyValidate("");
             };
          }else{
             if(res.data){
                if(res.data.phone){
                   setPhoneValidate(res.data.phone[0]);
                };
             }
          };
          return res;
        }else{
          setPhoneValidate("Phone number is minium 6 and maxinum 11")
        }
    };

    const passwordHandler = e => {
        const numericValue = e.target.value.replace(/\D/g, "");
        e.target.value = numericValue;
        setPassword(e.target.value);
        setPasswordValidate("");
      }
    const conPasswordHandler = e => {
        const numericValue = e.target.value.replace(/\D/g, "");
        e.target.value = numericValue;
        setConPassword(e.target.value);
        setConPasswordValidate("");
      }

  return (
    <>
        <Layout>
            <Breadcrumb title="Create User" />
            <Container fluid={true}>
                <Row>
                    <Card>
                        <Col sm="12" xl="6">
                            <Row>
                                <Col sm="12">
                                    <Form className="theme-form" onSubmit={registerSubmitHandler}>
                                        <CardBody>
                                            <FormGroup>
                                                <Label className="col-form-label pt-0" >Name</Label>
                                                <Input className="form-control" value={name} type="text" placeholder="Enter Name" onChange={e => {
                                                    setName(e.target.value);
                                                    setNameValidate("");
                                                }}/>
                                                {
                                                    nameValidate && <div className="invalid-feedback d-block">{nameValidate}</div>
                                                }
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Phone</Label>
                                                <InputGroup>
                                                    <Input className="form-control" value={phone} type="number" placeholder="Enter Phone" aria-label="" onChange={e => {
                                                        setPhone(e.target.value)
                                                        setPhoneValidate("");
                                                    }}/>
                                                    <InputGroupText className='p-0 border-0'>
                                                        <Button type='button' style={{
                                                            padding: '0.5rem 0.75rem',
                                                        }} color="primary" onClick={getOtp}>GET OTP</Button>
                                                    </InputGroupText>
                                                </InputGroup>
                                                {
                                                    phoneValidate && <div className="invalid-feedback d-block">{phoneValidate}</div>
                                                }
                                            </FormGroup>
                                            <FormGroup>
                                                <Label htmlFor="">OTP Code</Label>
                                                <Input className="form-control shadow-none" value={verify} style={{borderColor: '#ced4da'}} type="number" readOnly placeholder="Code" />
                                                {
                                                    verifyValidate && <div className="invalid-feedback d-block">{verifyValidate}</div>
                                                }
                                            </FormGroup>
                                            {/* <FormGroup>
                                                <Label htmlFor="">Bank Name</Label>
                                                <Input type="select" name="select" value={bankName} className="form-control" onChange={e => {
                                                        setBankName(e.target.value)
                                                        setBankNameValidate("");
                                                    }}>
                                                    <option value="">Select</option>
                                                    <option value="Thai Bank(Kasikorn)">Thai Bank(Kasikorn)</option>
                                                    <option value="Wave">Wave</option>
                                                    <option value="Kpay">Kpay</option>
                                                    <option value="KBZ Mobile Banking">KBZ Mobile Banking</option>
                                                    <option value="AYA Mobile Banking">AYA Mobile Banking</option>
                                                    <option value="CB Mobile Banking">CB Mobile Banking</option>
                                                    {
                                                        paymentType.length > 0 &&
                                                        paymentType.map((el,i) => 
                                                            <option key={i} value={el.id}>{el.name}</option>
                                                        )
                                                    }
                                                </Input>
                                                {
                                                    bankNameValidate && <div className="invalid-feedback d-block">{bankNameValidate}</div>
                                                }
                                            </FormGroup> */}
                                            {/* <FormGroup>
                                                <Label htmlFor="">Bank Account Name</Label>
                                                <Input className="form-control" type="text" value={bankAccountName} placeholder="Bank Account Name" onChange={e => {
                                                        setBankAccountName(e.target.value)
                                                        setBankAccoutNameValidate("");
                                                    }}/>
                                                {
                                                    bankAccoutNameValidate && <div className="invalid-feedback d-block">{bankAccoutNameValidate}</div>
                                                }
                                            </FormGroup> */}
                                            {/* <FormGroup>
                                                <Label htmlFor="">Bank Account Number</Label>
                                                <Input className="form-control" type="text" value={bankAccountNumber} placeholder="Bank Account Number" onChange={e => {
                                                        setBankAccountNumber(e.target.value)
                                                        setBankAccoutNumberValidate("");
                                                    }}/>
                                                {
                                                    bankAccoutNumberValidate && <div className="invalid-feedback d-block">{bankAccoutNumberValidate}</div>
                                                }
                                            </FormGroup> */}
                                            <FormGroup>
                                                <Label htmlFor="">Password</Label>
                                                <Input className="form-control" type="password" value={password} placeholder="Password" onChange={passwordHandler}/>
                                                {
                                                    passwordValidate && <div className="invalid-feedback d-block">{passwordValidate}</div>
                                                }
                                            </FormGroup>
                                            <FormGroup>
                                                <Label htmlFor="">Confirm Password</Label>
                                                <Input className="form-control" type="password" value={conPassword} placeholder="Password" onChange={conPasswordHandler}/>
                                                {
                                                    conPasswordValidate && <div className="invalid-feedback d-block">{conPasswordValidate}</div>
                                                }
                                            </FormGroup>
                                            {
                                                authRole === 'admin' && 
                                            <FormGroup>
                                                <Label htmlFor="">Roles</Label>
                                                <Input type="select" name="select" className="form-control" onChange={e => setUserRole(e.target.value)}>
                                                    <option value="">Select Role</option>
                                                    <option value="user">User</option>
                                                    <option value="streamer">Streamer</option>
                                                    <option value="staff">Staff</option>
                                                </Input>
                                            </FormGroup>   
                                            }
                                            {
                                                userRole === 'user' && 
                                            <FormGroup>
                                                <Label htmlFor="">Streamers</Label>
                                                <Input type="select" name="select" className="form-control" onChange={e => setStreamerId(e.target.value)}>
                                                    <option value="">Select streamer</option>
                                                    {
                                                        streamers.length > 0 &&
                                                        streamers.map((streamer,i) => 
                                                            <option value={streamer.id} key={i}>{streamer.code}</option>
                                                        )
                                                    }
                                                </Input>
                                            </FormGroup>
                                            }
                                        </CardBody>
                                        <CardFooter>
                                            <Button color="primary" className="me-1">Submit</Button>
                                        </CardFooter>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                        {
                            loading && <Loader />
                        }
                    </Card>
                </Row>
            </Container>  
        </Layout>
    </>
    
  )
}

export default UserCreate