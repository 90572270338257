import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import { getMethodAuth } from "../api";
import { useNavigate } from "react-router";
import Pagination from "../utils/Pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { statusHandler } from "../store/slices/authSlice";
const PromotionUsers = () => {
  const [loading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const [from, setFrom] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  let promoCount = 0;
  const dispatch = useDispatch();
  const {value} = useSelector(state => state.notiCount);

  useEffect(() => {
    (async () => {
      let token = JSON.parse(localStorage.getItem("admin_auth"));
      if (token) {
        let res = await getMethodAuth(`/promotion-like-count?page=${page}`, token.token);
        if (res) {
          setLoading(false);
          if (res.message === "Unauthenticated.") {
            localStorage.removeItem("admin_auth");
            navigate("/admin/login");
          }
          if (res.status === "success") {
            if (res.data) {
              if (res.data.from) {
                setFrom(res.data.from)
              }
              if (res.data.last_page) {
                setTotalPage(res.data.last_page);
              };
              setPromotions(res.data?.data);
              res?.data?.data.map(p => promoCount += p.like_count)
              localStorage.setItem('p_count', promoCount)
              dispatch(statusHandler(!value))
            };
          }
        } else {
          setLoading(false);
        }
      }
    })();
  }, []);
  return (
    <>
      <Layout>
        <Breadcrumbs title="Promotion Users" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="position-relative">
                  <div>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Promotion Type"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Promotion Count"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotions.length > 0 &&
                          promotions.map((promotion, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${i + from
                                }.`}</th>
                              <td className="text-center">
                                <Link to={`${promotion.promotion_id}`}>
                                  <div className="badge bg-primary">
                                    {promotion.promotion}
                                  </div>
                                </Link>
                              </td>
                              <td className="text-center">
                                {promotion.like_count}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  {loading && <Loader />}
                </CardBody>
                {
                  totalPage > 1 &&
                  <CardFooter className='d-flex justify-content-end'>
                    <Pagination
                      setPage={setPage}
                      totalPage={totalPage}
                      setLoading={setLoading}
                    />
                  </CardFooter>
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default PromotionUsers
