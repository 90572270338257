import {Users, UserPlus, Key, ArrowUpCircle, DollarSign, Clock, Folder, Dribbble, Monitor, Image, PenTool, Repeat, ArrowDownCircle, Home, Type, GitPullRequest, List, Percent, Gift, CheckSquare, Tablet } from 'react-feather'

export const MENUITEMS = [
    {
        Items: [
            { path: `/admin/dashboard`, icon: Home, title: 'Dashboard', type: 'link' },
            { path: `/admin/users`, icon: Users, title: 'Users', type: 'link' },
            { path: `/admin/app-name`, icon: Tablet, title: 'App Name', type: 'link' },
            {
                title: 'Create User', icon: UserPlus, path: `#`, type: 'sub', children: [
                    { path: `/admin/create-user`, type: 'link', title: 'User' },
                    { path: `/admin/create-streamer`, type: 'link', title: 'Streamer' },
                ]
            },
            { path: `/admin/auto-approve-deposit`, icon: CheckSquare, title: 'Auto Approve Deposit', type: 'link' },
            { path: `/admin/deposit`, icon: ArrowDownCircle, badge: "badge badge-danger", depositNoti: true, title: 'Deposit', type: 'link' },
            { path: `/admin/withdraw`, icon: ArrowUpCircle, badge: "badge badge-danger", withdrawNoti: true, title: 'Withdraw', type: 'link' },
            {
                title: 'Manual Cashier', icon: Repeat, path: `#`, type: 'sub', children: [
                    { path: `/admin/manual-deposit`, type: 'link', title: 'Manual Deposit' },
                    { path: `/admin/manual-withdraw`, type: 'link', title: 'Manual Withdraw' },
                ]
            },
            { path: `/admin/payment-providers`, icon: DollarSign, title: 'Payment Providers', type: 'link' },
            { path: `/admin/transaction-history`, icon: Clock, title: 'Trans History', type: 'link' },
            { path: `/admin/balance-transfer`, icon: Repeat, title: 'Balance Trans', type: 'link' },
            {
                title: 'Reports', icon: Folder, path: `#`, type: 'sub', children: [
                    { path: `/admin/reports/provider`, type: 'link', title: 'Provider Report ' },
                    { path: `/admin/reports/users`, type: 'link', title: 'Users Report' },
                    { path: `/admin/reports/deposit-payment`, type: 'link', title: 'Deposit Report' },
                    { path: `/admin/reports/withdraw-payment`, type: 'link', title: 'Withdraw Report' },
                    { path: `/admin/reports/deposit-withdraw`, type: 'link', title: 'Deposit Withdraw' },
                    { path: `/admin/reports/streamers`, type: 'link', title: 'Streamers Report' },
                ]
            },
            {
                title: 'Sport Betting', icon: Dribbble, path: `#`, type: 'sub', children: [
                    { path: `/admin/betting/betting-body`, type: 'link', title: 'Betting Body' },
                    { path: `/admin/betting/betting-parlay`, type: 'link', title: 'Betting Parlay' },
                ]
            },
            {
                title: 'Change Password', icon: Key, path: `#`, type: 'sub', children: [
                    { path: `/admin/change-user-password`, type: 'link', title: 'Change User Password' },
                    { path: `/admin/change-self-password`, type: 'link', title: 'Change Self Password' },
                ]
            },
            { path: `/admin/games-betting`, icon: Monitor, title: 'Games Betting', type: 'link' },
            { path: `/admin/providers`, icon: UserPlus, title: 'Providers', type: 'link' },
            { path: `/admin/providers-percent`, icon: Percent, title: 'Providers Percent', type: 'link' },
            { path: `/admin/game-types`, icon: Type, title: 'Game Types', type: 'link' },
            { path: `/admin/game-connect`, icon: GitPullRequest, title: 'Game Connect', type: 'link' },
            { path: `/admin/games`, icon: Monitor, title: 'Games', type: 'link' },
            { path: `/admin/games-list`, icon: List, title: 'Games List', type: 'link' },
            { path: `/admin/banner`, icon: Image, title: 'Banner', type: 'link' },
            { path: `/admin/caption`, icon: PenTool, title: 'Banner Caption', type: 'link' },
            {
                title: 'Promotions', icon: Gift, path: `#`, badge: "badge badge-danger", promotionNoti: true, type: 'sub', children: [
                    { path: `/admin/promotions`, type: 'link', title: 'Create Promotion' },
                    { path: `/admin/promotions/users`, type: 'link', title: 'Promotion Users' },
                ]
            },
            { path: `/admin/popup`, icon: Image, title: 'Ads Popup', type: 'link' },
            // { path: `/admin/sms-phone`, icon: Mail, title: 'SMS Phone', type: 'link' },
        ]
    },
]