import React, { useState } from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Input, FormGroup, Form, CardFooter, Modal, ModalHeader, ModalBody, Label, ModalFooter, Button } from 'reactstrap';
import Layout from './Layout'
import { useEffect } from 'react';
import { getMethodAuth, postMethod } from '../api';
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import Pagination from '../utils/Pagination';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useridHandler } from '../store/slices/getGameBettingWithid';
import UserBalance from '../utils/UserBalance';
import TableScroll from '../utils/TableScroll';

const User = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [totalPage, setTotalPage] = useState(0);
    const [from, setFrom] = useState(1);
    const [page, setPage] = useState(1);
    const [searchName, setSearchName] = useState("");
    const [searchId, setSearchId] = useState("");
    const [action, setAction] = useState("");
    const [status, setStatus] = useState("");
    const [toPage, setToPage] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(new Date());
    const [refr, setRefr] = useState(true);
    const dispatch = useDispatch();
    const [streamers, setStreamers] = useState([]);
    const [findStreamerUser, setFindStreamerUser] = useState("");
    const [editStreamerUser, setEditStreamerUser] = useState("");
    const [userEditid, setUserEditid] = useState("");
    const [editUser, setEditUser] = useState(false);
    const [filterRole, setFilterRole] = useState('');
    const [authRole, setAuthRole] = useState('');
    const [appName, setAppName] = useState([]);
    const [searchAppName, setSearchAppName] = useState('');

    function dateFormat(date) {
        if (date) {
            function padTo2Digits(num) {
                return num.toString().padStart(2, '0');
            };
            const year = date.getFullYear();
            const month = padTo2Digits(date.getMonth() + 1);
            const day = padTo2Digits(date.getDate());

            const withHyphens = [year, month, day].join('-');
            return withHyphens;
        };
    };

    useEffect(() => {
        setLoading(true)
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        (async () => {
            if (token) {
                setAuthRole(token.user_role)
                // const res = await getMethodAuth(`/users?name=${searchName}&id=${searchId}&user_status=${status}&status=${action}&start_date=${startDate && dateFormat(startDate)}&end_date=${dateFormat(endDate)}&app_name_id=${process.env.REACT_APP_ID}&page=${page}&limit=50`, token.token);
                const res = await getMethodAuth(`/users-lists?streamer_id=${findStreamerUser}&name=${searchName}&id=${searchId}&user_status=${status}&status=${action}&start_date=${startDate && dateFormat(startDate)}&end_date=${dateFormat(endDate)}&app_name_id=${searchAppName}&page=${page}&limit=50&role=${filterRole}`, token.token);

                // admin only
                if (token.user_role === 'admin') {
                    const resStreamer = await getMethodAuth(`/streamers`, token.token);

                    if (resStreamer) {
                        if (res.message === "Unauthenticated.") {
                            localStorage.removeItem('admin_auth');
                            navigate("/admin/login")
                        };
                        if (resStreamer.status === "success") {
                            if (resStreamer.data) {
                                setStreamers(resStreamer.data)
                            };
                        };
                    };
                }

                if (res) {
                    setLoading(false);
                    if (res.message === "Unauthenticated.") {
                        localStorage.removeItem('admin_auth');
                        navigate("/admin/login")
                    };
                    if (res.status === "success") {
                        if (res.data) {
                            setData(res.data)
                        };
                        if (res.meta) {
                            if (res.meta.from) {
                                setFrom(res.meta.from)
                            }
                            if (res.meta.last_page) {
                                setTotalPage(res.meta.last_page);
                            };
                            if (res.meta.to) {
                                setToPage(res.meta.to);
                            };
                            if (res.meta.total) {
                                setTotalUsers(res.meta.total);
                            };
                        };
                    };
                } else {
                    setLoading(false);
                };

                let resAppName = await getMethodAuth(`/app-name`, token?.token);
                if (resAppName?.status === 'success') {
                    setAppName(resAppName.data);
                }
            } else {
                setLoading(false);
            }
        })()
    }, [refr, page]);

    const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };
    const closeToggleHandler = async (e, data) => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        const statusToggle = {
            status: e.target.checked ? "active" : "disable"
        };
        if (token) {
            const res = await postMethod(`/users/${data.id}/update`, statusToggle, token.token);
            if (res) {
                setLoading(false);
                if (res.message === "Unauthenticated.") {
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                };
            } else {
                setLoading(false);
            };
        } else {
            setLoading(false);
        }
    };

    const searchSubmitHandler = () => {
        setRefr(prev => !prev);
    };

    const searchHandler = e => {
        setSearchName(e.target.value);
    };
    const searchIdHandler = e => {
        setSearchId(e.target.value);
    };

    const statusHandler = e => {
        setStatus(e.target.value);
    };

    const actionHandler = e => {
        setAction(e.target.value);
    };
    const roleHandler = e => {
        setFilterRole(e.target.value);
    };
    const editOpenModal = () => {
        setEditUser(!editUser);
    };

    const editSubmitHandler = async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        if (token) {
            const res = await postMethod(`/users/${userEditid}/update`, { type: 'streamer', streamer_id: editStreamerUser }, token.token);
            if (res) {
                setLoading(false);
                if (res.message === "Unauthenticated.") {
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                };

                if (res.success) {
                    setEditUser(false);
                    setUserEditid("");
                    setRefr(!refr)
                }
            } else {
                setLoading(false);
            };
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            <Layout>
                <Breadcrumb title="Users" />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4'>
                                    <Col sm="12">
                                        <Form>
                                            <Row style={{ justifyContent: 'flex-end' }}>
                                                <Col md="4 px-1 mb-2">
                                                    <Input className="form-control" onChange={searchIdHandler} type="number" placeholder="Search ID" />
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input className="form-control" onChange={searchHandler} type="text" placeholder="Search Name" />
                                                </Col>
                                                {/* setFindStreamerUser */}
                                                {
                                                    authRole === 'admin' &&
                                                    <>
                                                        <Col md="4 px-1 mb-2">
                                                            <Input type="select" name="select" className="form-control" onChange={e => setFindStreamerUser(e.target.value)}>
                                                                <option value="">Select streamer</option>
                                                                {
                                                                    streamers.length > 0 &&
                                                                    streamers.map((streamer, i) =>
                                                                        <option value={streamer.id} key={i}>{streamer.code}</option>
                                                                    )
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </>
                                                }
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={statusHandler}>
                                                        <option value="">Select Status</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={actionHandler}>
                                                        <option value="">Select Action</option>
                                                        <option value="active">Unban</option>
                                                        <option value="disable">Ban</option>
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={e => setSearchAppName(e.target.value)}>
                                                        <option value="">Select App Name</option>
                                                        {
                                                            appName.length > 0 &&
                                                            appName.map((el,index) => 
                                                                <option key={`app_name_key_${index}_${el.id}`} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={roleHandler}>
                                                        <option value="">Select Role</option>
                                                        <option value="streamer">Streamer</option>
                                                        {/* <option value="partner">Partner</option> */}
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading ? "Loading..." : "Submit"}</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <TableScroll>
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Reg Phone"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Balance"}</b></th>
                                                    {/* <th className='text-center text-white' scope="col"><b>{"Bank Type"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Bank Acc Name"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Bank Acc"}</b></th> */}
                                                    <th className='text-center text-white' scope="col"><b>{"Streamer's"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Reg Date & Time"}</b></th>
                                                    {/* <th className='text-center text-white' scope="col"><b>{"Game ID"}</b></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((user, i) =>
                                                        <tr key={i}>
                                                            <th className='text-center' scope="row">{`${i + from}.`}</th>
                                                            <td className='text-center'>{user.id}</td>
                                                            <td className='text-center'>
                                                                <Link to={'/admin/games-betting/'} onClick={() => dispatch(useridHandler(user.id))}>
                                                                    {user.name}
                                                                </Link>
                                                            </td>
                                                            <td className='text-center'>{user.phone}</td>
                                                            <td style={{ textAlign: 'right' }}>{
                                                                <UserBalance
                                                                    status={user.user_status}
                                                                    id={user.id}
                                                                />
                                                            }</td>
                                                            {/* <td className='text-center'>{user.bank_name}</td>
                                                <td className='text-center'>{user.bank_acc_name}</td>
                                                <td className='text-center'>{user.bank_acc_no}</td> */}
                                                            <td className='text-center'>{user.streamer_name ??
                                                                <div>
                                                                    <span onClick={() => {
                                                                        editOpenModal();
                                                                        setUserEditid(user.id);
                                                                    }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                                </div>
                                                            }</td>
                                                            <td className='text-center'>{
                                                                user.user_status === "Active" ? <i className="fa fa-circle font-success f-12" /> :
                                                                    <i className="fa fa-circle font-danger f-12" />
                                                            }</td>
                                                            <td className='text-center'>{<FormGroup switch>
                                                                <Input
                                                                    type="switch"
                                                                    className='bg-primary'
                                                                    style={{
                                                                        height: '20px',
                                                                        width: '35px',
                                                                        userSelect: 'none',
                                                                        cursor: 'pointer'
                                                                    }}

                                                                    defaultChecked={user.status === "active" ? true : false}
                                                                    onClick={(e) => closeToggleHandler(e, user)}
                                                                />
                                                            </FormGroup>}
                                                            </td>
                                                            <td className='text-center'>{user.created_at}</td>
                                                            {/* <td className='text-center'>{user.userId}</td> */}
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                            {/* <tfoot>
                                            <tr className='bg-dark'>
                                                <td colSpan={4} className="text-center text-white">Total</td>
                                                <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.balance),0).toFixed(2): 0)}</td>
                                                <td colSpan={6} className='text-end text-white'></td>
                                            </tr>
                                      </tfoot> */}
                                        </Table>
                                    </TableScroll>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-between align-items-center'>
                                    {
                                        data.length > 0 &&
                                        <div style={{
                                            fontSize: '16px',
                                            letterSpacing: '0.6px',
                                            wordSpacing: '3px'
                                        }}>Showing {chunk(from)} to {chunk(toPage)} of {chunk(totalUsers)} entries</div>
                                    }

                                    <Pagination
                                        setPage={setPage}
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>
            {
                <Modal isOpen={editUser} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit User
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Streamers</Label>
                                <Input type="select" name="select" className="form-control" onChange={e => setEditStreamerUser(e.target.value)}>
                                    <option value="">Select streamer</option>
                                    {
                                        streamers.length > 0 &&
                                        streamers.map((streamer, i) =>
                                            <option value={streamer.id} key={i}>{streamer.code}</option>
                                        )
                                    }
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
        </>

    )
}

export default User