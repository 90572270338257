import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import { getMethodAuth } from "../api";
import { useNavigate, useParams } from "react-router";
import Pagination from "../utils/Pagination";
import TableScroll from "../utils/TableScroll";
const PromotionUsersDetail = () => {
  const [loading,setLoading] = useState(true);
  const [promotion,setPromotion] = useState([]);
  const [users,setUsers] = useState([])
  const [from,setFrom] = useState(1);
  const [totalPage,setTotalPage] = useState(1);
  const [page,setPage] = useState(1);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    (async () => {
      let token = JSON.parse(localStorage.getItem("admin_auth"));
      if (token) {
        let res = await getMethodAuth(`/promotion-like-count?promotion_id=${params.id}`, token.token);
        if (res) {
          setLoading(false);
          if (res.message === "Unauthenticated.") {
            localStorage.removeItem("admin_auth");
            navigate("/admin/login");
          }
          if (res.status === "success") {
            if(res.data){
                setPromotion(res.data?.data)
            };
          }
        } else {
          setLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let token = JSON.parse(localStorage.getItem("admin_auth"));
      if (token) {
        let res = await getMethodAuth(`/promotion-like-count?promotion_id=${params.id}&type=1&page=${page}`, token.token);
        if (res) {
          setLoading(false);
          if (res.message === "Unauthenticated.") {
            localStorage.removeItem("admin_auth");
            navigate("/admin/login");
          }
          if (res.status === "success"){
            if(res.data){
                if(res.data.from){
                    setFrom(res.data.from)
                }
                if(res.data.last_page){
                    setTotalPage(res.data.last_page);
                };
              setUsers(res.data?.data);
            };
          }
        } else {
          setLoading(false);
        }
      }
    })();
  }, []);
  return (
    <>
       <Layout>
        <Breadcrumbs title={`Promotion - ${promotion[0]?.promotion}`} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"User ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Reg Phone"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Date and Time"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(users)}
                        {users.length > 0 ?
                          users.map((user, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">
                                {user.user_id}
                              </td>
                              <td className="text-center">
                                {user.user_name}
                              </td>
                              <td className="text-center">
                                {user.phone}
                              </td>
                              <td className="text-center">
                                {user.date}
                              </td>
                            </tr>
                          )):
                            <tr>
                                <td colSpan={5} className="text-center">No found user</td>
                            </tr>
                          }
                      </tbody>
                    </Table>
                  </TableScroll>
                  {loading && <Loader />}
                </CardBody>
                {
                    totalPage > 1 &&
                    <CardFooter className='d-flex justify-content-end'>
                        <Pagination
                            setPage={setPage} 
                            totalPage={totalPage}
                            setLoading={setLoading}
                        />
                    </CardFooter>
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default PromotionUsersDetail
