import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getMethod, uploadPostMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Loader from "../layout/loader/Loader";

const PopupBox = () => {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");
  const [refr, setRefr] = useState(true);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [popupImage, setPopupImage] = useState("");
  const [popupImageValidate, setPopupImageValidate] = useState("");
  const navigate = useNavigate();
  const [active, setActive] = useState('');

  useEffect(() => {
    (async () => {
      let res = await getMethod(
        `/popup?app_name_id=${process.env.REACT_APP_ID}`
      );
      if (res) {
        setLoading(false);
        if (res.status === "success") {
          if (res.data) {
            setPhoto(res.data.popup_photo);
            setActive(res.data.status)
          }
        }
      }
    })();
  }, [refr]);

  const updateOpenModal = () => {
    setUpdatePopup(!updatePopup);
  };

  const updateSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("popup_photo", popupImage);
      const token = JSON.parse(localStorage.getItem("admin_auth"));
      const response = await axios.request(
        await uploadPostMethod("/update-popup/1", formData, token.token)
      );
      if (response.data.status === "success") {
        setLoading(false);
        setUpdatePopup(false);
        setRefr(!refr);
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          setUpdatePopup(false);
          SweetAlert.fire({
            icon: "error",
            width: 300,
            title: <p>Fail</p>,
            text: error.response.data.message,
          });
        }
        if (error.response.data) {
          if (error.response.data.message === "Unauthenticated.") {
            localStorage.removeItem("admin_auth");
            navigate("/admin/login");
          }
        }
        if (error.response.data.message) {
          if (error.response.data.message.photo) {
            setPopupImageValidate(error.response.data.message.photo[0]);
          }
        }
      }
    }
  };

  const popupToggler = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("status", active === "active" ? "disable" : "active")
      const token = JSON.parse(localStorage.getItem("admin_auth"));
      const response = await axios.request(
        await uploadPostMethod("/update-popup/1", formData, token.token)
      );
      if (response.data.status === "success") {
        setLoading(false);
        setActive(response?.data?.status)
        setPhoto(response?.data?.popup_photo)
        setRefr(!refr);
      }
    } catch (error) {
      return error.message;
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Ads Popup" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-between">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      className="bg-primary"
                      style={{
                        height: "20px",
                        width: "35px",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      checked={active === 'active' ? true : false}
                      onClick={popupToggler}
                    />
                  </FormGroup>
                  <Button
                    color="primary"
                    onClick={updateOpenModal}
                  >
                    Update
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <div className="d-flex justify-content-center align-items-center">
                    {photo ? (
                      <img
                        src={photo}
                        alt="ads_popup"
                        style={{ maxWidth: "380px", borderRadius: "5px" }}
                      />
                    ) : (
                      <p className="text-center text-danger fw-bold py-5" style={{fontSize: 20}}>Ads Popup photo is disabled.</p>
                    )}
                  </div>
                  {loading && <Loader />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={updatePopup} toggle={updateOpenModal} centered>
          <Form onSubmit={updateSubmitHandler}>
            <ModalHeader toggle={updateOpenModal}>Update Ads Popup</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setPopupImage(e.target.files[0]);
                    setPopupImageValidate("");
                  }}
                />
                {popupImageValidate && (
                  <div className="invalid-feedback d-block">
                    {popupImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={updateOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default PopupBox;
