import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { deleteMethod, getMethod, uploadPostMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Loader from "../layout/loader/Loader";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";

const Promotions = () => {
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editPromotion, setEditPromotion] = useState(false);
  const [createPromotion, setCreatePromotion] = useState(false);
  const [promotionImage, setPromotionImage] = useState("");
  const [promotionEnTitle, setPromotionEnTitle] = useState("");
  const [promotionMmTitle, setPromotionMmTitle] = useState("");
  const [promotionDescription, setPromotionDescription] = useState("");
  const [promotionFrom, setPromotionFrom] = useState("");
  const [promotionTo, setPromotionTo] = useState("");
  const [promotionImageValidate, setPromotionImageValidate] = useState("");
  const [promotionEnTitleValidate, setPromotionEnTitleValidate] = useState("");
  const [promotionMmTitleValidate, setPromotionMmTitleValidate] = useState("");
  const [promotionFromValidate, setPromotionFromValidate] = useState("");
  const [promotionToValidate, setPromotionToValidate] = useState("");
  const [promotionDescriptionValidate, setPromotionDescriptionValidate] =
    useState("");
  const [paymentImageValidate, setPaymentImageValidate] = useState("");
  const [promotionEditId, setPromotionEditId] = useState("");
  const navigate = useNavigate();
  const [from, setFrom] = useState(1);
  const [shortDes, setShortDes] = useState('');

  useEffect(() => {
    (async () => {
      let token = JSON.parse(localStorage.getItem("admin_auth"));
      if (token) {
        let res = await getMethod(
          `/promotions?app_name_id=${process.env.REACT_APP_ID}`
        );
        if (res) {
          setLoading(false);
          if (res.message === "Unauthenticated.") {
            localStorage.removeItem("admin_auth");
            navigate("/admin/login");
          }
          if (res.status === "success") {
            if (res.data) {
              setPromotions(res.data);
            }
          }
        } else {
          setLoading(false);
        }
      }
    })();
  }, [refr]);

  useEffect(() => {
    let result = promotions.find((promo) => promo.id === promotionEditId);
    if (result) {
      setPromotionEnTitle(result.en_title);
      setPromotionMmTitle(result.mm_title);
      setPromotionFrom(result.from);
      setPromotionTo(result.to);
      setPromotionDescription(result.description);
    }
  }, [promotionEditId, editPromotion]);

  const createOpenModal = () => {
    setCreatePromotion(!createPromotion);
    setPromotionEnTitle("");
    setPromotionMmTitle("");
    setPromotionFrom("");
    setPromotionTo("");
    setPromotionDescription("");
    setPromotionEnTitleValidate("");
    setPromotionMmTitleValidate("");
    setPromotionFromValidate("");
    setPromotionToValidate("");
    setPromotionDescriptionValidate("");
  };

  const editOpenModal = () => {
    setEditPromotion(!editPromotion);
  };

  //   for create promotion handler
  const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("photo", promotionImage);
      formData.append("en_title", promotionEnTitle);
      formData.append("mm_title", promotionMmTitle);
      formData.append("from", promotionFrom);
      formData.append("to", promotionTo);
      formData.append("description", promotionDescription);
      const token = JSON.parse(localStorage.getItem("admin_auth"));
      const response = await axios.request(
        await uploadPostMethod("/create-promotion", formData, token.token)
      );
      if (response.data.status === "success") {
        setLoading(false);
        setCreatePromotion(false);
        setRefr(!refr);
        setPromotionImage("");
        setPromotionEnTitle("");
        setPromotionMmTitle("");
        setPromotionFrom("");
        setPromotionTo("");
        setPromotionDescription("");
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          setCreatePromotion(false);
          SweetAlert.fire({
            icon: "error",
            width: 300,
            title: <p>Fail</p>,
            text: error.response.data.message,
          });
        }
        if (error.response.data) {
          if (error.response.data.message === "Unauthenticated.") {
            localStorage.removeItem("admin_auth");
            navigate("/admin/login");
          }
        }
        if (error.response.data.message) {
          if (error.response.data.message.photo) {
            setPromotionImageValidate(error.response.data.message.photo[0]);
          }
          if (error.response.data.message.en_title) {
            setPromotionEnTitleValidate(
              error.response.data.message.en_title[0]
            );
          }
          if (error.response.data.message.mm_title) {
            setPromotionMmTitleValidate(
              error.response.data.message.mm_title[0]
            );
          }
          if (error.response.data.message.description) {
            setPromotionDescriptionValidate(
              error.response.data.message.description[0]
            );
          }
          if (error.response.data.message.from) {
            setPromotionFromValidate(error.response.data.message.from[0]);
          }
          if (error.response.data.message.to) {
            setPromotionToValidate(error.response.data.message.to[0]);
          }
        }
      }
    }
  };

  //   for edit promotion handler
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (promotionEditId) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("photo", promotionImage);
        formData.append("en_title", promotionEnTitle);
        formData.append("mm_title", promotionMmTitle);
        formData.append("from", promotionFrom);
        formData.append("to", promotionTo);
        formData.append("description", promotionDescription);
        const token = JSON.parse(localStorage.getItem("admin_auth"));
        const response = await axios.request(
          await uploadPostMethod(
            `/update-promotion/${promotionEditId}`,
            formData,
            token.token
          )
        );
        if (response.data.status === "success") {
          editOpenModal();
          setLoading(false);
          setRefr(!refr);
          setPromotionImage("");
          setPromotionEnTitle("");
          setPromotionMmTitle("");
          setPromotionFrom("");
          setPromotionTo("");
          setPromotionDescription("");
        }
      } catch (error) {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            setCreatePromotion(false);
            SweetAlert.fire({
              icon: "error",
              width: 300,
              title: <p>Fail</p>,
              text: error.response.data.message,
            });
          }
          if (error.response.data) {
            if (error.response.data.message === "Unauthenticated.") {
              localStorage.removeItem("admin_auth");
              navigate("/admin/login");
            }
          }
        }
      }
    }
  };

  // for promotion delete handler
  const deleteHandler = async (id) => {
    const token = JSON.parse(localStorage.getItem("admin_auth"));
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          if (token) {
            const res = await deleteMethod(
              `/delete-promotion/${id}`,
              token.token
            );
            if (res) {
              setLoading(false);
              if (res.message === "Unauthenticated.") {
                localStorage.removeItem("admin_auth");
                navigate("/admin/login");
              }
              if (res.status === "error") {
                SweetAlert.fire({
                  icon: "error",
                  width: 300,
                  title: <p>Fail</p>,
                  text: res.message,
                });
              }
              if (res.status === "success") {
                SweetAlert.fire({
                  title: "Success!",
                  text: "You has been deleted.",
                  icon: "success",
                  width: 300,
                });
                setRefr(!refr);
              }
              if (res.status_code === "403") {
                alert(res.message);
                localStorage.removeItem("admin_auth");
                navigate("/admin/login");
              }
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })();
      }
    });
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Promotions" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Button
                    color="primary"
                    onClick={createOpenModal}
                  >
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Photo"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"EN_Title"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"MM_Title"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Description"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"FROM"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"TO"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Action"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotions.length > 0 &&
                          promotions.map((promotion, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">
                                <img
                                  src={promotion.photo}
                                  alt="promotion"
                                  style={{
                                    width: "40px",
                                    borderRadius: "10px",
                                  }}
                                />
                              </td>
                              <td className="text-center">
                                {promotion.en_title}
                              </td>
                              <td className="text-center">
                                {promotion.mm_title}
                              </td>
                              <td className="text-center">
                                {/* {promotion.description} */}
                                {
                                  promotion.description.length > 35 ? promotion.description.slice(0, 35) + ' . . .' : promotion.description
                                }
                              </td>

                              <td className="text-center">{promotion.from}</td>
                              <td className="text-center">{promotion.to}</td>

                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal();
                                      setPromotionEditId(promotion.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                  <span
                                    onClick={() => {
                                      deleteHandler(promotion.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "#ff0000",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                  {loading && <Loader />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createPromotion} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Promotion</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo ( Size = 360 x 200 )</Label>
                <Input
                  className="form-control"
                  type="file"
                  onChange={(e) => {
                    setPromotionImage(e.target.files[0]);
                    setPaymentImageValidate("");
                  }}
                />
                {paymentImageValidate && (
                  <div className="invalid-feedback d-block">
                    {paymentImageValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">En Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="En Title"
                  onChange={(e) => {
                    setPromotionEnTitle(e.target.value);
                    setPromotionEnTitleValidate("");
                  }}
                />
                {promotionEnTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionEnTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Mm Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Mm Title"
                  onChange={(e) => {
                    setPromotionMmTitle(e.target.value);
                    setPromotionMmTitleValidate("");
                  }}
                />
                {promotionMmTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionMmTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">From - To</Label>
                <DateRangeFormat
                  setStartDate={setPromotionFrom}
                  setEndDate={setPromotionTo}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Description</Label>
                <textarea
                  className="form-control"
                  placeholder="Description"
                  onChange={(e) => {
                    setPromotionDescription(e.target.value);
                    setPromotionDescriptionValidate("");
                  }}
                ></textarea>
                {promotionDescriptionValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionDescriptionValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editPromotion} toggle={editOpenModal} centered>
          <Form onSubmit={editSubmitHandler}>
            <ModalHeader toggle={editOpenModal}>Edit Promotion</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo ( Size = 360 x 200 )</Label>
                <Input
                  className="form-control"
                  type="file"
                  onChange={(e) => {
                    setPromotionImage(e.target.files[0]);
                    setPaymentImageValidate("");
                  }}
                />
                {paymentImageValidate && (
                  <div className="invalid-feedback d-block">
                    {paymentImageValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">En Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={promotionEnTitle}
                  placeholder="En Title"
                  onChange={(e) => {
                    setPromotionEnTitle(e.target.value);
                    setPromotionEnTitleValidate("");
                  }}
                />
                {promotionEnTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionEnTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Mm Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={promotionMmTitle}
                  placeholder="Mm Title"
                  onChange={(e) => {
                    setPromotionMmTitle(e.target.value);
                    setPromotionMmTitleValidate("");
                  }}
                />
                {promotionMmTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionMmTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">From - To</Label>
                <DateRangeFormat
                  setStartDate={setPromotionFrom}
                  setEndDate={setPromotionTo}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Description</Label>
                <textarea
                  className="form-control"
                  value={promotionDescription}
                  placeholder="Description"
                  onChange={(e) => {
                    setPromotionDescription(e.target.value);
                    setPromotionDescriptionValidate("");
                  }}
                ></textarea>
                {promotionDescriptionValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionDescriptionValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default Promotions;
