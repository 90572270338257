import React, { useState,useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import {  getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb';
import Loader from '../layout/loader/Loader'
import Pagination from '../utils/Pagination';
import Layout from './Layout';
import DatePicker from "react-datepicker";
import DateRangeFormat from '../utils/DateRangeFormat';

const BettingParlay = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [totalPage,setTotalPage] = useState(0);
  const [from,setFrom] = useState(1);
  const [page,setPage] = useState(1);
  const navigate = useNavigate();
  const [showDetail,setShowDetail] = useState(false);
  const [detailData,setDetailData] = useState("");
  const [betDetail,setBetDetail] = useState([]);
  const [changeColor,setChangeColor] = useState([]);
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");
  const [refr,setRefr] = useState(true);
  const [appName, setAppName] = useState([]);
  const [searchAppName, setSearchAppName] = useState('');

  const fetchAppName = async () => {
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    if(token){
      const res = await getMethodAuth(`/app-name`, token.token);
      if(res){
        if(res.status === "success"){
          setAppName(res.data);
        }
      }
    }
  }

  useEffect(() => {
    fetchAppName();
  }, [])

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/sport-book/slot/betting-history?product=SB&start_date=${startDate}&end_date=${endDate}&bet_type=PARLAY&app_name_id=${searchAppName}&page=${page}`, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                      setData([]);
                      setData(res.data);
                    };
                    if(res.meta){
                        if(res.meta.from){
                            setFrom(res.meta.from)
                        }
                        if(res.meta.last_page){
                            setTotalPage(res.meta.last_page);
                        };
                    };
                };
            }else{
                setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[refr,page]);

  const searchSubmitHandler = () => {
    setRefr(prev => !prev);
  };

  const detailModal = (data) => {
    setShowDetail(!showDetail);
  };
  useEffect(()=>{
     let tempResult  = [];
     let color = [];
    let result = detailData.split("-[").map(el => {
        let value = el.substring(el.lastIndexOf(']'),el.length);
        return value.split(']')[1];
    });
    result.map((el,i) => {
        if(el === ""){
            result.splice(i, 1);
        }
        
    });
    result.map((el,i) => {
        if(el === ""){
            result.splice(i, 1);
        };

    });

    if(result.length > 0){
        result.map((el,i) => {
           tempResult.push(el);
        });
    };
    
    if(tempResult.length > 0){
        tempResult.map((el,i) => {
            if(el ===""){
                return tempResult.splice(i,1);
            };
            if(el === "SOCCER"){
                color.push(tempResult[++i]);
                setChangeColor(color)
            }
        });
    };
    setBetDetail(tempResult);
  },[detailData]);

  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  const slider = document.querySelector('.table-scroll');
  if(slider){
    let mouseDown = false;
    let startX, scrollLeft;
    let startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    };
        let stopDragging = function (event) {
        mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
    e.preventDefault();
    if(!mouseDown) { return; }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  };
  

  return (
    <>
        <Layout>
            <Breadcrumbs title="Betting Parlay" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="4 px-1 mb-2">
                                                    <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate}/>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={e => setSearchAppName(e.target.value)}>
                                                        <option value="">Select App Name</option>
                                                        {
                                                            appName.length > 0 &&
                                                            appName.map((el,index) => 
                                                                <option key={`app_name_key_${index}_${el.id}`} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading? "Loading...":"Submit"}</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll" style={{cursor: 'grab'}}>
                                        <Table className="table-border-vertical" style={{width: '170%'}}>
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Reg Phone"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Ref No"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Bet Time"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Play Time"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Bet Type"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Bet Status"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Win / Lose"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Bet"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Payout"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Comm:"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Profit"}</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                data.length > 0 &&
                                                data.map((row,i) => 
                                                    <tr key={i}>
                                                        <th className='text-center' scope="row">{`${i+from}.`}</th>
                                                        <td className='text-center'>{row.user_name}</td>
                                                        <td className='text-center'>{row.user_phone}</td>
                                                        <td className='text-center'>{row.ref_no}</td>
                                                        <td className='text-center'>{row.start_time}</td>
                                                        <td className='text-center'>{row.match_time}</td>
                                                        <td className='text-center'>
                                                        <span onClick={() => {
                                                                detailModal();
                                                                setDetailData(row.bet_detail);
                                                        }} style={{fontWeight:'bolder',cursor: 'pointer',userSelect:'none'}}>
                                                                {row.bet_type}
                                                            </span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <span className={`badge badge-${row.bet_status === "Accept" ?"primary": row.bet_status === "END"?  "success" : "danger"}`}>{row.bet_status}</span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <span className={`badge badge-${row.status === "Waiting"? "warning": "dark"}`} >{row.status}</span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <span className={`badge badge-${row.win_lose === "LOSE"? "danger": row.win_lose === "WIN"?  "success" : "primary"}`}>{row.win_lose}</span>
                                                        </td>
                                                        <td className='text-end'>{row.bet? chunk(row.bet): 0}</td>
                                                        <td className='text-end'>{row.payout? chunk(row.payout): 0}</td>
                                                        <td className='text-end'>{row.commission? chunk(row.commission): 0}</td>
                                                        <td className='text-end'>{row.win_lose_amount? chunk(row.win_lose_amount): 0}</td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                            <tfoot>
                                                <tr className='bg-dark'>
                                                    <td colSpan={10} className="text-end text-white">Total</td>
                                                    <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.bet), 0).toFixed(2): 0)}</td>
                                                    <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.payout), 0).toFixed(2): 0)}</td>
                                                    <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.commission), 0).toFixed(2): 0)}</td>
                                                    <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.win_lose_amount), 0).toFixed(2): 0)}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={showDetail} toggle={detailModal} centered>
                <ModalHeader toggle={detailModal}>
                        Bet Detail
                </ModalHeader>
                <ModalBody>
                    <ul>
                        {
                            betDetail.length > 0 &&
                            betDetail.map((detail,i) => 
                                <li key={i} style={{color: changeColor.includes(detail) && "red"}}>
                                    {
                                        detail
                                    }
                                </li>
                            )
                        }
                    </ul>
                </ModalBody>
            </Modal>
        }
    </>
  )
}

export default BettingParlay