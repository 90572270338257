import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signin from '../auth/Signin';
import Deposit from '../components/Deposit';
import User from '../components/User';
import Withdraw from '../components/Withdraw';
import Loader from '../layout/loader/Loader';
import ValidateToken from '../auth/ValidateToken';
import PaymentProvider from '../components/PaymentProvider';
import TransactionHistory from '../components/TransactionHistory';
import ReportProvider from '../components/ReportProvider';
import ReportUsers from '../components/ReportUsers';
import ReportPayment from '../components/ReportPayment';
import BettingBody from '../components/BettingBody';
import GameBetting from '../components/GameBetting';
import Banner from '../components/Banner';
import Caption from '../components/Caption';
import BettingParlay from '../components/BettingParlay';
import UserCreate from '../components/UserCreate';
import ManualDeposit from '../components/ManualDeposit';
import ManualWithdraw from '../components/ManualWithdraw';
import ChangePassword from '../components/ChangePassword';
import BalanceTrans from '../components/BalanceTrans';
import ReportWithdrawPayment from '../components/ReportWithdrawPayment';
import Dashboard from '../components/Dashboard';
import Providers from '../components/Providers';
import GameTypes from '../components/GameTypes';
import GameConnect from '../components/GameConnect';
import Games from '../components/Games';
import GamesList from '../components/GamesList';
import { useSelector } from 'react-redux';
import ChangeSelfPassword from '../components/ChangeSelfPassword';
import CreateStreamer from '../components/CreateStreamer';
import ProviderPercent from '../components/ProviderPercent';
import PromotionUsersDetail from '../components/PromotionUsersDetail';
import PromotionUsers from '../components/PromotionUsers';
import Promotions from '../components/Promotions';
import PopupBox from '../components/PopupBox';
import ReportDepositWithdraw from '../components/ReportDepositWithdraw';
import ReportStreamer from '../components/ReportStreamer';
import ReportStreamerDetail from '../components/ReportStreamerDetail';
import AutoApproveDeposit from '../components/AutoApproveDeposit';
import AppName from '../components/AppName';

const Routers = () => {
  const [role, setRole] = useState('');
  const { authValue } = useSelector(state => state.auth);

  useEffect(() => {
    const getAuth = localStorage.getItem('admin_auth');
    if (getAuth) {
      const token = JSON.parse(getAuth);
      setRole(token.user_role);
    };
  }, [authValue])

  return (
    <Router basename={'/'}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={`/`} element={<ValidateToken>{role === 'streamer' || 'partner' ? <User /> : <Dashboard />}</ValidateToken>}></Route>
          <Route path={`/admin/login`} element={<Signin />}></Route> 
          <Route path={`/admin/users`} element={<ValidateToken><User /></ValidateToken>}></Route>
          <Route path={`/admin/deposit`} element={<ValidateToken><Deposit /></ValidateToken>}></Route>
          <Route path={`/admin/withdraw`} element={<ValidateToken><Withdraw /></ValidateToken>}></Route>
          {
            role === 'admin' &&
            <>
              <Route path={`/admin/app-name`} element={<ValidateToken><AppName /></ValidateToken>}></Route>
              <Route path={`/admin/dashboard`} element={<ValidateToken><Dashboard /></ValidateToken>}></Route>
              <Route path={`/admin/create-user`} element={<ValidateToken><UserCreate /></ValidateToken>}></Route>
              <Route path={`/admin/create-streamer`} element={<ValidateToken><CreateStreamer /></ValidateToken>}></Route>
              <Route path={`/admin/auto-approve-deposit`} element={<ValidateToken><AutoApproveDeposit /></ValidateToken>}></Route>
              <Route path={`/admin/manual-deposit`} element={<ValidateToken><ManualDeposit /></ValidateToken>}></Route>
              <Route path={`/admin/manual-withdraw`} element={<ValidateToken><ManualWithdraw /></ValidateToken>}></Route>
              <Route path={`/admin/change-user-password`} element={<ValidateToken><ChangePassword /></ValidateToken>}></Route>
              <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword /></ValidateToken>}></Route>
              <Route path={`/admin/games-betting`} element={<ValidateToken><GameBetting /></ValidateToken>}></Route>
              <Route path={`/admin/payment-providers`} element={<ValidateToken><PaymentProvider /></ValidateToken>}></Route>
              <Route path={`/admin/transaction-history`} element={<ValidateToken><TransactionHistory /></ValidateToken>}></Route>
              <Route path={`/admin/balance-transfer`} element={<ValidateToken><BalanceTrans /></ValidateToken>}></Route>
              <Route path={`/admin/reports/provider`} element={<ValidateToken><ReportProvider /></ValidateToken>}></Route>
              <Route path={`/admin/reports/users`} element={<ValidateToken><ReportUsers /></ValidateToken>}></Route>
              <Route path={`/admin/reports/deposit-payment`} element={<ValidateToken><ReportPayment /></ValidateToken>}></Route>
              <Route path={`/admin/reports/withdraw-payment`} element={<ValidateToken><ReportWithdrawPayment /></ValidateToken>}></Route>
              <Route path={`/admin/reports/deposit-withdraw`} element={<ValidateToken><ReportDepositWithdraw /></ValidateToken>}></Route>
              <Route path={`/admin/reports/streamers`} element={<ValidateToken><ReportStreamer /></ValidateToken>}></Route>
              <Route path={`/admin/reports/streamers/:streamerId`} element={<ValidateToken><ReportStreamerDetail/></ValidateToken>}></Route>
              <Route path={`/admin/betting/betting-body`} element={<ValidateToken><BettingBody /></ValidateToken>}></Route>
              <Route path={`/admin/betting/betting-parlay`} element={<ValidateToken><BettingParlay /></ValidateToken>}></Route>
              <Route path={`/admin/banner`} element={<ValidateToken><Banner /></ValidateToken>}></Route>
              <Route path={`/admin/caption`} element={<ValidateToken><Caption /></ValidateToken>}></Route>
              <Route path={`/admin/providers`} element={<ValidateToken><Providers /></ValidateToken>}></Route>
              <Route path={`/admin/providers-percent`} element={<ValidateToken><ProviderPercent /></ValidateToken>}></Route>
              <Route path={`/admin/game-types`} element={<ValidateToken><GameTypes /></ValidateToken>}></Route>
              <Route path={`/admin/game-connect`} element={<ValidateToken><GameConnect /></ValidateToken>}></Route>
              <Route path={`/admin/games`} element={<ValidateToken><Games /></ValidateToken>}></Route>
              <Route path={`/admin/games-list`} element={<ValidateToken><GamesList /></ValidateToken>}></Route>
              <Route path={`/admin/promotions`} element={<ValidateToken><Promotions /></ValidateToken>}></Route>
              <Route path={`/admin/promotions/users`} element={<ValidateToken><PromotionUsers /></ValidateToken>}></Route>
              <Route path={`/admin/promotions/users/:id`} element={<ValidateToken><PromotionUsersDetail /></ValidateToken>}></Route>
              <Route path={`/admin/popup`} element={<ValidateToken><PopupBox /></ValidateToken>}></Route>
            </>
          }
          {
            role === 'staff' &&
            <>
              <Route path={`/admin/dashboard`} element={<ValidateToken><Dashboard /></ValidateToken>}></Route>
              <Route path={`/admin/create-user`} element={<ValidateToken><UserCreate /></ValidateToken>}></Route>
              <Route path={`/admin/manual-deposit`} element={<ValidateToken><ManualDeposit /></ValidateToken>}></Route>
              <Route path={`/admin/manual-withdraw`} element={<ValidateToken><ManualWithdraw /></ValidateToken>}></Route>
              <Route path={`/admin/change-user-password`} element={<ValidateToken><ChangePassword /></ValidateToken>}></Route>
              <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword /></ValidateToken>}></Route>
              <Route path={`/admin/games-betting`} element={<ValidateToken><GameBetting /></ValidateToken>}></Route>
              <Route path={`/admin/payment-providers`} element={<ValidateToken><PaymentProvider /></ValidateToken>}></Route>
              <Route path={`/admin/transaction-history`} element={<ValidateToken><TransactionHistory /></ValidateToken>}></Route>
              <Route path={`/admin/balance-transfer`} element={<ValidateToken><BalanceTrans /></ValidateToken>}></Route>
              <Route path={`/admin/reports/provider`} element={<ValidateToken><ReportProvider /></ValidateToken>}></Route>
              <Route path={`/admin/reports/users`} element={<ValidateToken><ReportUsers /></ValidateToken>}></Route>
              <Route path={`/admin/reports/deposit-payment`} element={<ValidateToken><ReportPayment /></ValidateToken>}></Route>
              <Route path={`/admin/reports/withdraw-payment`} element={<ValidateToken><ReportWithdrawPayment /></ValidateToken>}></Route>
              <Route path={`/admin/reports/deposit-withdraw`} element={<ValidateToken><ReportDepositWithdraw /></ValidateToken>}></Route>
              <Route path={`/admin/betting/betting-body`} element={<ValidateToken><BettingBody /></ValidateToken>}></Route>
              <Route path={`/admin/betting/betting-parlay`} element={<ValidateToken><BettingParlay /></ValidateToken>}></Route>
            </>
          }
          {
            role === 'streamer' &&
            <>
              <Route path={`/admin/deposit`} element={<ValidateToken><Deposit /></ValidateToken>}></Route>
              <Route path={`/admin/withdraw`} element={<ValidateToken><Withdraw /></ValidateToken>}></Route>
              <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword /></ValidateToken>}>
              </Route>
            </>
          }
          {
            role === 'partner' &&
            <>
                <Route path={`/admin/manual-deposit`} element={<ValidateToken><ManualDeposit/></ValidateToken>}></Route>
                <Route path={`/admin/manual-withdraw`} element={<ValidateToken><ManualWithdraw/></ValidateToken>}></Route>
                <Route path={`/admin/change-user-password`} element={<ValidateToken><ChangePassword/></ValidateToken>}></Route>
                <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword/></ValidateToken>}></Route>
                <Route path={`/admin/games-betting`} element={<ValidateToken><GameBetting/></ValidateToken>}></Route>
                <Route path={`/admin/transaction-history`} element={<ValidateToken><TransactionHistory/></ValidateToken>}></Route>
                <Route path={`/admin/balance-transfer`} element={<ValidateToken><BalanceTrans/></ValidateToken>}></Route>
                <Route path={`/admin/reports/provider`} element={<ValidateToken><ReportProvider/></ValidateToken>}></Route>
                <Route path={`/admin/reports/users`} element={<ValidateToken><ReportUsers/></ValidateToken>}></Route>
                <Route path={`/admin/reports/deposit-payment`} element={<ValidateToken><ReportPayment/></ValidateToken>}></Route>
                <Route path={`/admin/reports/withdraw-payment`} element={<ValidateToken><ReportWithdrawPayment/></ValidateToken>}></Route>
                <Route path={`/admin/reports/deposit-withdraw`} element={<ValidateToken><ReportDepositWithdraw /></ValidateToken>}></Route>
            </>
          }

          {/* <Route path={`/admin/sms-phone`} element={<ValidateToken><Smsphone/></ValidateToken>}></Route> */}
        </Routes>
      </Suspense>
    </Router>
  )
}

export default Routers