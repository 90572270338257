import React, { useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody ,Input, FormGroup, Button, Form, Label} from 'reactstrap';
import Layout from './Layout';
import { createPortal } from 'react-dom';
import Loader from '../layout/loader/Loader';
import { postMethod } from '../api';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';

const ChangePassword = () => {
    const [loading,setLoading] = useState(false);
    const [userid,setUserid] = useState("");
    const [newPass,setNewPass] = useState("");
    const [conPass,setConPass] = useState("");
    const [useridValidate,setUseridValidate] = useState("");
    const [newValidate,setNewValidate] = useState("");
    const [conValidate,setConValidate] = useState("");
    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            user_id: userid,
            password: newPass,
            password_confirmation: conPass
        };
        const getStore = localStorage.getItem('admin_auth');
        const token = JSON.parse(getStore);
        if(token){
            let res = await postMethod('/okie/change-password',data, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate('/');
                }
                 if(res.success){
                    SweetAlert.fire({
                        icon: 'success',
                        width: 300,
                        title: "Success",
                        text: "Password changed successfully",
                    }).then(res => {
                        if(res.isConfirmed){
                            setConPass("");
                            setUserid("");
                            setNewPass("");
                        };
                    });;
                 }else{
                    if(res.errors){
                        if(res.errors.password){
                            setNewValidate(res.errors.password[0]);
                        };
                        if(res.errors.user_id){
                            setUseridValidate(res.errors.user_id[0]);
                        };
                        if(res.errors.password_confirmation){
                            setConValidate(res.errors.password_confirmation[0]);
                        };
                    };
                    if(res.data){
                        if(res.data.error){
                            SweetAlert.fire({
                                icon: 'error',
                                width: 300,
                                title: "Error",
                                text: res.data.error,
                            });
                        }
                    }
                 };
            }else{
                setLoading(false);
            };
        };
    }
    

  return (
    <>
        <Layout>
            <Breadcrumb title="Change User Password" />
            <Container fluid={true}>
                <Row>
                    <Card>
                            <Row>
                                <Col sm="12">
                                    <Form className="theme-form" onSubmit={submitHandler}>
                                        <CardBody className='position-relative'>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label className="col-form-label pt-0" >User ID</Label>
                                                        <Input className="form-control" value={userid} type="number" placeholder="Enter User ID" onChange={e => {
                                                            setUserid(e.target.value);
                                                            setUseridValidate("");
                                                        }}/>
                                                        {
                                                            useridValidate && <div className="invalid-feedback d-block">{useridValidate}</div>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label className="col-form-label pt-0" >New Password</Label>
                                                        <Input className="form-control" value={newPass} type="number" placeholder="Enter New Password" onChange={e => {
                                                            setNewPass(e.target.value);
                                                            setNewValidate("");
                                                        }}/>
                                                        {
                                                            newValidate && <div className="invalid-feedback d-block">{newValidate}</div>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label className="col-form-label pt-0" >Confirm Password</Label>
                                                        <Input className="form-control" value={conPass} type="number" placeholder="Enter Confirm Password" onChange={e => {
                                                            setConPass(e.target.value);
                                                            setConValidate("");
                                                        }}/>
                                                        {
                                                            conValidate && <div className="invalid-feedback d-block">{conValidate}</div>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label className="col-form-label pt-0" >Submit</Label>
                                                        <Button style={{
                                                            width: '100%'
                                                        }} color="primary" type='submit' className="me-1">Submit</Button>
                                                    </FormGroup>
                                                    
                                                </Col>
                                            </Row>
                                            {
                                                loading &&  <Loader />
                                            }
                                        </CardBody>
                                    </Form>
                                </Col>
                            </Row>
                    </Card>
                </Row>
            </Container>  
        </Layout>
        {
          createPortal(loading && <Loader />, document.getElementById('portal'))
        }
    </> 
  )
}

export default ChangePassword