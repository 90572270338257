import React, { useState } from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Input, FormGroup, Button, Form, Label } from 'reactstrap';
import Layout from './Layout';
import { createPortal } from 'react-dom';
import Loader from '../layout/loader/Loader';
import { postMethod } from '../api';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';

const ChangeSelfPassword = () => {
    const [loading, setLoading] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [conPass, setConPass] = useState("");
    const [newValidate, setNewValidate] = useState("");
    const [conValidate, setConValidate] = useState("");
    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const getStore = localStorage.getItem('admin_auth');
        const token = JSON.parse(getStore);
        console.log(token)
        const data = {
            user_id: token.user_id,
            password: newPass,
            password_confirmation: conPass
        };
        if (token) {
            let res = await postMethod('/okie/self-change-password', data, token.token);
            if (res) {
                setLoading(false);
                if (res.message === "Unauthenticated.") {
                    localStorage.removeItem('admin_auth');
                    navigate('/');
                }
                if (res.success) {
                    SweetAlert.fire({
                        icon: 'success',
                        width: 300,
                        title: "Success",
                        text: "Password changed successfully",
                    }).then(res => {
                        if (res.isConfirmed) {
                            setConPass("");
                            setNewPass("");
                        };
                    });;
                } else {
                    if (res.errors) {
                        if (res.errors.password) {
                            setNewValidate(res.errors.password[0]);
                        };
                        if (res.errors.password_confirmation) {
                            setConValidate(res.errors.password_confirmation[0]);
                        };
                    };
                    if (res.data) {
                        if (res.data.error) {
                            SweetAlert.fire({
                                icon: 'error',
                                width: 300,
                                title: "Error",
                                text: res.data.error,
                            });
                        }
                    }
                };
            } else {
                setLoading(false);
            };
        };
    }

    return (
        <>
            <Layout>
                <Breadcrumb title="Change Self Password" />
                <Container fluid={true}>
                    <Row>
                        <Card>
                            <Row>
                                <Col sm="12">
                                    <Form className="theme-form" onSubmit={submitHandler}>
                                        <CardBody className='position-relative'>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label className="col-form-label pt-0" >New Password</Label>
                                                        <Input className="form-control" value={newPass} type="text" placeholder="Enter New Password" onChange={e => {
                                                            setNewPass(e.target.value);
                                                            setNewValidate("");
                                                        }} />
                                                        {
                                                            newValidate && <div className="invalid-feedback d-block">{newValidate}</div>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label className="col-form-label pt-0" >Confirm Password</Label>
                                                        <Input className="form-control" value={conPass} type="text" placeholder="Enter Confirm Password" onChange={e => {
                                                            setConPass(e.target.value);
                                                            setConValidate("");
                                                        }} />
                                                        {
                                                            conValidate && <div className="invalid-feedback d-block">{conValidate}</div>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label className="col-form-label pt-0" >Submit</Label>
                                                        <button type='submit' className="w-100 btn btn-primary">Submit</button>
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                            {
                                                loading && <Loader />
                                            }
                                        </CardBody>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                </Container>
            </Layout>
            {
                createPortal(loading && <Loader />, document.getElementById('portal'))
            }
        </>
    )
}

export default ChangeSelfPassword