import React,{useEffect,useState} from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethodAuth, postMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import Pagination from '../utils/Pagination'

const AppName = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [refr,setRefr] = useState(true);
  const [createApp,setCreateApp] = useState(false);
  const [editApp,setEditApp] = useState(false);
  const [appName,setAppName] = useState("");
  const [appNameValidate,setAppNameValidate] = useState("");
  const [totalPage,setTotalPage] = useState(0);
  const [from,setFrom] = useState(1);
  const [page,setPage] = useState(1);
  const [status,setStatus] = useState("");
  const [editId,setEditId] = useState("");
  const navigate = useNavigate();
  const [role, setRole] = useState('');

  useEffect(()=>{
    (async ()=> {
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        if (token) {
            setRole(token.user_role);
            let res = await getMethodAuth(`/app-name?page=${page}`, token?.token);
            if(res){
                setLoading(false);
                if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login");
                  };
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                };
                if(res.status === "success"){
                    if(res.data){
                        setData(res?.data)
                        // let temp = [];
                        // setData([]);
                        // if(res.data.length > 0){
                        //     res.data.map(el => {
                        //         el.status === "active"? el.status =  : el.status = <i className="fa fa-circle font-danger f-12" />
                        //         if(el.name !== "Okie"){
                        //             temp.push(el);
                        //         };
                        //     });
                        // };
                        // setData(temp);
                    };
                };
            }else{
                setLoading(false);
            };
        } else {
            navigate('/admin/login')
        }
    })();
 },[refr,page]);

 const createOpenModal = () => {
    setCreateApp(!createApp);
    setAppName("");
    setAppNameValidate("");
 };

 const editOpenModal = () => {
    setEditApp(!editApp);
    setAppName("");
    setAppNameValidate("");
 };

 const statusHandler = e => {
    setStatus(e.target.value);
 };


 const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    (async ()=> {
        if(token){
           let res = await postMethod('/app-name',{name: appName}, token.token);
           if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                };
                if(res.errors){
                    if(res.errors.name){
                        setAppNameValidate(res.errors.name);
                    };
                };
                if(res.status === "success"){
                    setRefr(!refr);
                    setCreateApp(!createApp);
                };
           };
        }
    })();
 };

 const editSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    (async ()=> {
        if(token){
           let res = await postMethod(`/app-name/${editId}/update`,{name: appName, status}, token.token);
           if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                };
                if(res.errors){
                    if(res.errors.name){
                        setAppNameValidate(res.errors.name);
                    };
                };
                if(res.status === "success"){
                    setRefr(!refr);
                    setEditApp(!editApp);
                };
           };
        }
    })();
 };

 
  return (
    <>
        <Layout>
            <Breadcrumbs title="App Name" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"App ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Merchant ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                                    {
                                                        role === 'admin' && 
                                                        <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                data.length > 0 &&
                                                data.map((apk,i) => 
                                                    <tr key={i} className="align-middle">
                                                        <th className='text-center' scope="row">{`${i+from}.`}</th>
                                                        <td className='text-center'>{apk.id}</td>
                                                        <td className='text-center'>{apk.name}</td>
                                                        <td className='text-center'>{apk.merchant_id}</td>
                                                        <td className='text-center'>{apk.status === 'active' ? <i className="fa fa-circle font-success f-12" /> : <i className="fa fa-circle font-danger f-12" />}</td>
                                                        {
                                                            role === 'admin' &&
                                                        <td className='text-center'>
                                                            <span onClick={() => {
                                                                    editOpenModal();
                                                                    setEditId(apk.id);
                                                                    setAppName(apk.name);
                                                                    setStatus(apk.status);
                                                                }
                                                            }><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                        </td>
                                                        }
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={createApp} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create App
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">App Name</Label>
                                <Input className="form-control"  type="text" placeholder="App Name" onChange={e => {
                                    setAppName(e.target.value);
                                    setAppNameValidate("");
                                }}/>
                                {
                                    appNameValidate && <div className="invalid-feedback d-block">{appNameValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
        {
            <Modal isOpen={editApp} toggle={editOpenModal} centered>
                    <Form onSubmit={editSubmitHandler}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit App
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">App Name</Label>
                                <Input className="form-control"  type="text" placeholder="App Name" value={appName} onChange={e => {
                                    setAppName(e.target.value);
                                    setAppNameValidate("");
                                }}/>
                                {
                                    appNameValidate && <div className="invalid-feedback d-block">{appNameValidate}</div>
                                }
                            </FormGroup>
                            <Row>
                                <Col>
                                    <div className="mb-0">
                                        <Label>App Status</Label>
                                        <Input type="select" name="select" className="form-control form-control-sm digits" value={status} onChange={statusHandler}>
                                            <option value="">Choose</option>
                                            <option value="active">Active</option>
                                            <option value="disable">Disabled</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default AppName
