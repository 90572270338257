import React, { useEffect, useState } from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Table, CardHeader} from 'reactstrap';
import Layout from './Layout';
import { Download, Gift, Share, TrendingUp, User, UserPlus } from 'react-feather';
import CountUp from 'react-countup';
import { getMethod, getMethodAuth } from '../api';
import Loader from '../layout/loader/Loader';
import DateRangeFormat from '../utils/DateRangeFormat';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
  );
  const chartsData = [];

const Dashboard = () => {
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState({});
  const [financialData,setFinancialData] = useState([]);
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");
  const [monthlyCountData,setMonthlyCountData] = useState([]);
  const token = JSON.parse(localStorage.getItem('admin_auth'));

  useEffect(()=>{
    (async () => {
        const resUsersChart  = await getMethod(`/each-month-user-count?app_name_id=${process.env.REACT_APP_ID}`);
        if(resUsersChart){
            if(resUsersChart.status === "success"){
                setMonthlyCountData(resUsersChart.data);
            };      
        };
    })();
  },[]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },  
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                font: {
                    size: 14,
                    weight: 'bold'
                }
            }
        },
        y: {
            ticks: {
                font: {
                    size: 14,
                    weight: 'bold'
                }
            }
        },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  useEffect(()=>{
    labels.map(m => {
        if(monthlyCountData.length > 0){
            monthlyCountData.map(month => {
                if(month.month === m){
                    chartsData.push(month.count);
                };
            })
        }
    });

  },[monthlyCountData]);

  const chartData = {
    labels,
    datasets: [
      {
        data: chartsData,
        borderColor: '#c1c1c1',
        borderWidth: 1,
        backgroundColor: [
            '#ff6384',
            '#36a2eb',
            '#cc65fe',
            '#ffce56',
            '#ff6384',
            '#36a2eb',
            '#cc65fe',
            '#ffce56',
            '#ff6384',
            '#36a2eb',
            '#cc65fe',
            '#ffce56',
        ],
        pointRadius: 7,
      },
    ],
  };

  useEffect(()=>{
      setLoading(true);
      (async ()=> {
        const res = await getMethodAuth(`/dashboard?app_name_id=${process.env.REACT_APP_ID}`, token.token);
        if(res.success){
           setData(res.data);
        };
      })();
  },[]);

  useEffect(()=>{
    setLoading(true);
    (async ()=> {
      const resFinancial  = await getMethodAuth(`/deposits-withdraws-mix-count?start_date=${startDate}&end_date=${endDate}&app_name_id=${process.env.REACT_APP_ID}`, token.token);
      if(resFinancial){
          setLoading(false);
          if(resFinancial.status === "success"){
              setFinancialData(resFinancial.data);
          };
            
      }
    })();
},[startDate,endDate]);


  const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };
  return (
    <>
        <Layout>
            <Breadcrumb title="Welcome Royal Casino" />
            <Container fluid={true}>
                <Row>
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                        <Card className="o-hidden mb-0">
                            <CardBody className="bg-info b-r-4">
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center"><User /></div>
                                <div className="media-body"><span className="m-0">{'Total User ( s )'}</span>
                                <h4 className="mb-0 counter"><CountUp end={data?.total_user} /></h4><User className="icon-bg" />
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                    <Card className="o-hidden mb-0">
                        <CardBody className="bg-info b-r-4">
                        <div className="media static-top-widget">
                            <div className="align-self-center text-center"><UserPlus /></div>
                            <div className="media-body"><span className="m-0">{"New User ( s )"}</span>
                            <h4 className="mb-0 counter"><CountUp end={data?.today_user} /></h4><UserPlus className="icon-bg" />
                            </div>
                        </div>
                        </CardBody>
                    </Card>
                    </Col>
                    {/* <Col sm="6" xl="3" lg="6" className='p-1'>
                    <Card className="o-hidden mb-0">
                        <CardBody className="bg-info b-r-4">
                        <div className="media static-top-widget">
                            <div className="align-self-center text-center"><DollarSign /></div>
                            <div className="media-body"><span className="m-0">{"User ( s ) Balance"}</span>
                            <h4 className="mb-0 counter"><CountUp end={allUsersBalance} /></h4><DollarSign className="icon-bg" />
                            </div>
                        </div>
                        </CardBody>
                    </Card>
                    </Col> */}
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                        <Card className="o-hidden mb-0">
                            <CardBody className="bg-info b-r-4">
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center"><Download /></div>
                                <div className="media-body"><span className="m-0">{"Deposit Today"}</span>
                                <h4 className="mb-0 counter"><CountUp end={data?.today_deposit} /></h4><Download className="icon-bg" />
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                        <Card className="o-hidden mb-0">
                            <CardBody className="bg-info b-r-4">
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center"><Download /></div>
                                <div className="media-body"><span className="m-0">{"New Deposit"}</span>
                                <h4 className="mb-0 counter"><CountUp end={data?.new_deposit} /></h4><Download className="icon-bg" />
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                        <Card className="o-hidden mb-0">
                            <CardBody className="bg-info b-r-4">
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center"><Share /></div>
                                <div className="media-body"><span className="m-0">{"Withdraw Today"}</span>
                                <h4 className="mb-0 counter"><CountUp end={data?.today_withdraw} /></h4><Share className="icon-bg" />
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                        <Card className="o-hidden mb-0">
                            <CardBody className="bg-info b-r-4">
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center"><Share /></div>
                                <div className="media-body"><span className="m-0">{"New Withdraw"}</span>
                                <h4 className="mb-0 counter"><CountUp end={data?.new_withdraw} /></h4><Share className="icon-bg" />
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                        <Card className="o-hidden mb-0">
                            <CardBody className="bg-info b-r-4">
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center"><TrendingUp /></div>
                                <div className="media-body"><span className="m-0">{"Today Profit"}</span>
                                <h4 className="mb-0 counter"><CountUp end={data?.today_profit} /></h4><TrendingUp className="icon-bg" />
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" xl="3" lg="6" className='p-1'>
                        <Card className="o-hidden mb-0">
                            <CardBody className="bg-info b-r-4">
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center"><Gift /></div>
                                <div className="media-body"><span className="m-0">{"Today Bonus"}</span>
                                <h4 className="mb-0 counter"><CountUp end={data?.today_bonus} /></h4><Gift className="icon-bg" />
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Card className='mt-5'>
                        <CardHeader className='row justify-content-between align-items-end'>
                            <Col md="3 mb-2">
                                <h5>Financial Report</h5>
                            </Col>
                            <Col md="4 mb-2">
                                <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate}/>
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive table-scroll position-relative">
                                <Table className="table-border-vertical">
                                    <thead>
                                        <tr className='bg-dark'>
                                            <th className='text-center text-white' scope="col"><b>{"Pay Account"}</b></th>
                                            <th className='text-center text-white' scope="col"><b>{"D Count"}</b></th>
                                            <th className='text-center text-white' scope="col"><b>{"W Count"}</b></th>
                                            <th className='text-center text-white' scope="col"><b>{"Deposit"}</b></th>
                                            <th className='text-center text-white' scope="col"><b>{"Withdraw"}</b></th>
                                            <th className='text-center text-white' scope="col"><b>{"Profit & Loss"}</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            financialData.length > 0 &&
                                            financialData.map((el,i) => 
                                            <tr key={i}>
                                                <td className='text-center'>{el.payment_provider}</td>
                                                <td className='text-center'>{el.d_count?el.d_count: 0}</td>
                                                <td className='text-center'>{el.w_count?el.w_count: 0}</td>
                                                <td className='text-end'>{chunk(el.d_amount?el.d_amount: 0)}</td>
                                                <td className='text-end'>{chunk(el.w_amount?el.w_amount: 0)}</td>
                                                <td className='text-end'>{chunk(el.profit? el.profit: 0)}</td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className='bg-dark'>
                                            <td colSpan={3} className="text-center text-white">Total</td>
                                            <td className='text-end text-white'>{chunk(financialData.length > 0 ? financialData.reduce((accumulator, currentValue) => accumulator + Number(currentValue.d_amount?currentValue.d_amount: 0),0): 0)}</td>
                                            <td className='text-end text-white'>{chunk(financialData.length > 0 ? financialData.reduce((accumulator, currentValue) => accumulator + Number(currentValue.w_amount?currentValue.w_amount:0),0): 0)}</td>
                                            <td className='text-end text-white'>{chunk(financialData.length > 0 ? financialData.reduce((accumulator, currentValue) => accumulator + Number(currentValue.profit?currentValue.profit: 0),0): 0)}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                                {
                                    loading && <Loader />
                                }
                            </div>

                            {/* <div style={{
                                margin: '40px auto 0 auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{width: '48%'}}>
                                    <VerBarChart chartTitle="New User" label="Register" />
                                </div>
                                <div style={{width: '48%'}}>
                                    <LineChart />
                                </div>
                            </div>
                            <div style={{
                                margin: '40px auto 0 auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{width: '48%'}}>
                                    <VerBarChart chartTitle="User Deposit" label="Deposit"/>
                                </div>
                                <div style={{width: '48%'}}>
                                    <VerBarChart chartTitle="User Withdraw" label="Withdraw"/>
                                </div>
                            </div> */}
                        </CardBody>
                    </Card>
                </Row>
                <Row>
                    <Card className='mt-5'>
                        <CardHeader className='row justify-content-between align-items-end'>
                            <Col md="3 mb-2">
                                <h5>New User Growth</h5>
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <Line options={options} data={chartData}/>
                        </CardBody>
                    </Card>
                </Row>
            </Container>  
        </Layout>
    </>
    
  )
}

export default Dashboard