import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import DateRangeFormat from '../utils/DateRangeFormat';
import TableScroll from '../utils/TableScroll';

const ReportProvider = () => {
  const [loading, setLoading] = useState(true);
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");
  const [data,setData] = useState([]);
  const navigate = useNavigate();
  const [refr,setRefr] = useState(true);
  const [appName, setAppName] = useState([]);
  const [searchAppName, setSearchAppName] = useState('');

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/provider-reporting?app_name_id=${searchAppName}&start_date=${startDate}&end_date=${endDate}`, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                        if(res.data){
                         setData([]);
                         setData(res.data);
                        }
                     };
                    //  if(res.meta){
                    //    if(res.meta.from){
                    //        setFrom(res.meta.from)
                    //    };
                    //    if(res.meta.last_page){
                    //        setTotalPage(res.meta.last_page);
                    //    };
                    //  };
                };
            }else{
              setLoading(false);
            };

            let resAppName = await getMethodAuth(`/app-name`, token?.token);
            if (resAppName?.status === 'success') {
                setAppName(resAppName.data);
            }
        }else{
            setLoading(false);
        }
    })();
  },[refr]);

  const searchSubmitHandler = () => {
    setRefr(prev => !prev);
  };

  const chunk = num => {
    let decimal = Number(num).toFixed(2);
    let str = decimal.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  return (
    <>
        <Layout>
            <Breadcrumbs title="Provider Report" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className='py-4'>
                                <Col sm="12">
                                    <Form >
                                        <Row className='justify-content-end'>
                                            <Col md="4 px-1 mb-2">
                                                <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate}/>
                                            </Col>
                                            <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={e => setSearchAppName(e.target.value)}>
                                                        <option value="">Select App Name</option>
                                                        {
                                                            appName.length > 0 &&
                                                            appName.map((el,index) => 
                                                                <option key={`app_name_key_${index}_${el.id}`} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                            <Col md="4 px-1 mb-2">
                                                <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading? "Loading...":"Submit"}</button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </CardHeader>
                            <CardBody className='position-relative'>
                                <TableScroll>
                                    <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Provider</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Bet Amount</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Bet Payout</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Bet Profit</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Comm:20%</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Profit</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((bal,i) => 
                                                    <tr key={i}>
                                                        <th className='text-center' scope="row">{i + 1}.</th>
                                                        <th className='text-center' scope="row">{bal.provider}</th>
                                                        <td style={{ textAlign: 'right' }}>{bal.sum_total_bet ? chunk(bal.sum_total_bet): 0}</td>
                                                        <td style={{ textAlign: 'right' }}>{bal.sum_total_payout ? chunk(bal.sum_total_payout): 0}</td>
                                                        <td style={{ textAlign: 'right' }}>{chunk(bal.sum_total_payout - bal.sum_total_bet)}</td>
                                                        <td style={{ textAlign: 'right' }}>{chunk((bal.sum_total_payout - bal.sum_total_bet)* 0.2)}</td>
                                                        <td style={{ textAlign: 'right' }}>{chunk((bal.sum_total_payout - bal.sum_total_bet) - ((bal.sum_total_payout - bal.sum_total_bet) * 0.2))}</td>
                                                    </tr>
                                                    )
                                                }
                                            </tbody>
                                            <tfoot>
                                                    <tr className='bg-dark'>
                                                        <td colSpan={2} className="text-center text-white">Total</td>
                                                        <td className='text-end text-white'>{data.length > 0 && chunk(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_bet),0).toFixed(2))}</td>
                                                        <td className='text-end text-white'>{data.length > 0 && chunk(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout),0).toFixed(2))}</td>
                                                        <td className='text-end text-white'>{data.length > 0 && chunk(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout - currentValue.sum_total_bet),0).toFixed(2))}</td>
                                                        <td className='text-end text-white'>{data.length > 0 && chunk(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout - currentValue.sum_total_bet) * 0.2,0).toFixed(2))}</td>
                                                        <td className='text-end text-white'>{data.length > 0 && chunk(data.reduce((accumulator, currentValue) => accumulator + Number((currentValue.sum_total_payout - currentValue.sum_total_bet) - ((currentValue.sum_total_payout - currentValue.sum_total_bet) * 0.2)),0).toFixed(2))}</td>
                                                    </tr>
                                            </tfoot>
                                    </Table>
                                </TableScroll>
                                {
                                    loading && <Loader />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default ReportProvider