import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethod, uploadPostMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import TableScroll from '../utils/TableScroll'

const GameTypes = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [refr,setRefr] = useState(true);
  const [editPayment,setEditPayment] = useState(false);
  const [createPayment,setCreatePayment] = useState(false);
  const [typeName,setTypeName] = useState("");
  const [typeImage,setTypeImage] = useState("");
  const [typeNameValidate,setTypeNameValidate] = useState("");
  const [typeImageValidate,setTypeImageValidate] = useState("")
  const [gameTypeid,setGameTypeid] = useState("");
  const [status,setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
    (async ()=> {
        let res = await getMethod(`/provider-types`);
            if(res){
              setLoading(false);
              if(res.status === "success"){
                    if(res.data){
                        setData([]);
                        let temp = [];
                        if(res.data){
                            if(res.data.length > 0){
                                res.data.map(el => {
                                    el.status === "active"? el.status = <i className="fa fa-circle font-success f-12" /> : el.status = <i className="fa fa-circle font-danger f-12" />
                                    return temp.push(el)
                                });
                            };
                            setData(temp);
                        };
                    };
              };
            }else{
                setLoading(false);
            };
    })();
 },[refr]);

 const editOpenModal = () => {
    setEditPayment(!editPayment);
    setTypeName("");
    setTypeImage("");
    setTypeNameValidate("");
    setTypeImageValidate("");
};

 const createOpenModal = () => {
    setCreatePayment(!createPayment);
    setTypeName("");
    setTypeImage("");
    setTypeNameValidate("");
    setTypeImageValidate("");
    setStatus("");
 };

 const statusHandler = e => {
    setStatus(e.target.value);
 };

 const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", typeName);
      formData.append("photo", typeImage);

      const token = JSON.parse(localStorage.getItem('admin_auth'));
      const response = await axios.request(await uploadPostMethod('/provider-types',formData, token.token));
      if(response.data.status === "success"){
          setLoading(false);
          setCreatePayment(false);
          setRefr(!refr);
          setTypeName("");
          setTypeImage("");
      };
      } catch (error) {
          if(error.response){
              setLoading(false);
              if(error.response.status === 403){
                setCreatePayment(false);
                SweetAlert.fire({
                    icon: 'error',
                    width: 300,
                    title: <p>Fail</p>,
                    text: error.response.data.message,
                })
              }
              if(error.response.data){
                  if(error.response.data.message === "Unauthenticated."){
                      localStorage.removeItem('admin_auth');
                      navigate("/admin/login")
                  };
              };
              if(error.response.data.message){
                    if(error.response.data.message.name){
                        setTypeNameValidate(error.response.data.message.name[0])
                    };
                    if(error.response.data.message.photo){
                        setTypeImageValidate(error.response.data.message.photo[0])
                    };
              };
          };
      };
 };

 const editSubmitHandler = async () => {
    setLoading(true);
    if(gameTypeid){
        setLoading(true);
        try {
           const formData = new FormData();
           formData.append("name", typeName);
           formData.append("photo", typeImage);
           formData.append("status", status);
           const token = JSON.parse(localStorage.getItem('admin_auth'));
           const response = await axios.request(await uploadPostMethod(`/provider-types/${gameTypeid}/update`,formData, token.token));
           if(response.data.status === "success"){
                setLoading(false);
                setEditPayment(false);
                setRefr(!refr);
                setTypeName("");
                setTypeImage("");
           };
           } catch (error) {
               if(error.response){
                    setLoading(false);
                    if(error.response.status === 403){
                        setCreatePayment(false);
                        SweetAlert.fire({
                            icon: 'error',
                            width: 300,
                            title: <p>Fail</p>,
                            text: error.response.data.message,
                        });
                    }
                    if(error.response.data){
                        if(error.response.data.message === "Unauthenticated."){
                            localStorage.removeItem('admin_auth');
                            navigate("/admin/login")
                        };
                    };
               };
           };
    }
 };

  return (
    <>
        <Layout>
            <Breadcrumbs title="Game Types" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <TableScroll>
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Photo</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Status</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                    data.length > 0 &&
                                                    data.map((el,i) => 
                                                    <tr key={i} className="align-middle">
                                                        <th className='text-center' scope="row">{`${i + 1}.`}</th>
                                                        <th className='text-center'>{el.name}</th>
                                                        <td className='text-center'>
                                                            <img src={el.photo} alt="payment" style={{width: '40px',borderRadius: '10px'}}/>
                                                        </td>
                                                        <td className='text-center'>{el.status}</td>
                                                        <td className='text-center'>
                                                            <div>
                                                                <span onClick={() => {
                                                                        editOpenModal();
                                                                        setGameTypeid(el.id);
                                                                }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                        </Table>
                                    </TableScroll>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={createPayment} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create Game Type
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Type Name</Label>
                                <Input className="form-control"  type="text" placeholder="Type Name" onChange={e => {
                                    setTypeName(e.target.value);
                                    setTypeNameValidate("");
                                }}/>
                                {
                                    typeNameValidate && <div className="invalid-feedback d-block">{typeNameValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Type Photo</Label>
                                <Input className="form-control"  type="file" placeholder="" onChange={e => {
                                    setTypeImage(e.target.files[0]);
                                    setTypeImageValidate("");
                                }}/>
                                {
                                    typeImageValidate && <div className="invalid-feedback d-block">{typeImageValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
        {
            <Modal isOpen={editPayment} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Payment Provider
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Type Name</Label>
                                <Input className="form-control" defaultValue={typeName}  type="text" placeholder="Payment Name" onChange={e => {
                                    setTypeName(e.target.value);
                                    setTypeNameValidate("");
                                }}/>
                                 {
                                    typeNameValidate && <div className="invalid-feedback d-block">{typeNameValidate}</div>
                                 }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Type Photo</Label>
                                <Input className="form-control" type="file" placeholder="" onChange={e => {
                                    setTypeImage(e.target.files[0]);
                                    setTypeImageValidate("");
                                }}/>
                            </FormGroup>
                            <Row>
                                <Col>
                                    <div className="mb-0">
                                        <Label>Type Status</Label>
                                        <Input type="select" name="select" className="form-control form-control-sm digits" onChange={statusHandler}>
                                            <option value="">Choose</option>
                                            <option value="active">Active</option>
                                            <option value="disable">Disabled</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default GameTypes