import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import DateRangeFormat from '../utils/DateRangeFormat';

const ReportDepositWithdraw = () => {
  const [loading, setLoading] = useState(true);
  const [searchId,setSearchId] = useState("");
  const [data,setData] = useState([]);
  const navigate = useNavigate();
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");
  const [refr,setRefr] = useState(true);

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/deposits-withdraws-user-report?app_name_id=${process.env.REACT_APP_ID}&user_id=${searchId}&start_date=${startDate && startDate}&end_date=${endDate}`, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                        setData(res.data);
                    };
                }else{
                    setData([]);
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[refr]);

  const searchSubmitHandler = () => {
    setRefr(prev => !prev);
  };

  const searchHandler = e => {
    setSearchId(e.target.value);
  };
  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };
  return (
    <>
        <Layout>
            <Breadcrumbs title="Deposit Withdraw Report" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="4 px-1 mb-2">
                                                    <Input className="form-control" onChange={searchHandler} type="number" placeholder="Search ID" />
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate}/>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading? "Loading...":"Submit"}</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                        <thead>
                                            <tr className='bg-dark'>
                                                <th className='text-center text-white' scope="col"><b>{"ID"}</b></th>
                                                <th className='text-center text-white' scope="col"><b>{"Player"}</b></th>
                                                <th className='text-center text-white' scope="col"><b>{"Pay Account"}</b></th>
                                                <th className='text-center text-white' scope="col"><b>{"D Count"}</b></th>
                                                <th className='text-center text-white' scope="col"><b>{"W Count"}</b></th>
                                                <th className='text-center text-white' scope="col"><b>{"Deposit"}</b></th>
                                                <th className='text-center text-white' scope="col"><b>{"Withdraw"}</b></th>
                                                <th className='text-center text-white' scope="col"><b>{"Profit & Loss"}</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.length > 0 &&
                                                data.map((el,i) => 
                                                <tr key={i}>
                                                    <td className='text-center'>{el.user_id}</td>
                                                    <td className='text-center'>{el.user_name}</td>
                                                    <td className='text-center'>{el.payment_provider}</td>
                                                    <td className='text-center'>{el.d_count?el.d_count: 0}</td>
                                                    <td className='text-center'>{el.w_count?el.w_count: 0}</td>
                                                    <td className='text-end'>{chunk(el.d_amount?el.d_amount: 0)}</td>
                                                    <td className='text-end'>{chunk(el.w_amount?el.w_amount: 0)}</td>
                                                    <td className='text-end'>{chunk(el.profit? el.profit: 0)}</td>
                                                </tr>
                                                )
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr className='bg-dark'>
                                                <td colSpan={5} className="text-center text-white">Total</td>
                                                <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.d_amount?currentValue.d_amount: 0),0): 0)}</td>
                                                <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.w_amount?currentValue.w_amount:0),0): 0)}</td>
                                                <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.profit?currentValue.profit: 0),0): 0)}</td>
                                            </tr>
                                        </tfoot>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default ReportDepositWithdraw;