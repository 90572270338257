import React, { useEffect, useState } from 'react'
import Layout from './Layout';
import ModalImage from "react-modal-image";
import Breadcrumb from '../layout/breadcrumb'
import { Card, CardBody, CardFooter, CardHeader, Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import Loader from '../layout/loader/Loader'
import { getMethod, getMethodAuth, postMethod, postMethodWithoutData } from '../api'
import { useNavigate } from 'react-router';
import SweetAlert from 'sweetalert2';
import Pagination from '../utils/Pagination';
// import inputDebounce from '../utils/inputDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { statusHandler } from '../store/slices/depositWithdrawNotiSlice';
import DateRangeFormat from '../utils/DateRangeFormat';
import TableScroll from '../utils/TableScroll';

const Deposit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [confirm, setConfirm] = useState(true);
    const navigate = useNavigate();
    const [totalPage, setTotalPage] = useState(0);
    const [from, setFrom] = useState(1);
    const [page, setPage] = useState(1);
    const [searchName, setSearchName] = useState("");
    const [searchId, setSearchId] = useState("");
    const [status, setStatus] = useState("");
    const { value } = useSelector(state => state.notiCount);
    const dispatch = useDispatch();
    const [depositTotal, setDepositTotal] = useState(0);
    const [refr, setRefr] = useState(true);
    const [autoApprove, setAutoApprove] = useState('');
    const [seenUsers, setSeenUsers] = useState([]);
    const [seenId, setSeenId] = useState('');

    useEffect(() => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        (async () => {
            if (token) {
                const res = await getMethodAuth('/not-user-role', token.token)
                if (res) {
                    setLoading(false);
                    if (res.status === 'success' || res.success) {
                        setSeenUsers(res?.data)
                    }
                } else {
                    setLoading(false);
                }
            }
            if (token) {
                const res = await getMethodAuth(`/slot/deposits?status=${status}&start_date=${startDate}&end_date=${endDate}&name=${searchName}&user_id=${searchId}&app_name_id=${process.env.REACT_APP_ID}&page=${page}&seen_id=${seenId}`, token.token);
                console.log(res)
                if (res) {
                    setLoading(false);
                    if (res.message === "Unauthenticated.") {
                        localStorage.removeItem('admin_auth');
                        navigate("/admin/login")
                    }
                    if (res.status === "success") {
                        if (res.data) {
                            setData([]);
                            setData(res.data);
                        };
                        if (res.meta) {
                            if (res.meta.from) {
                                setFrom(res.meta.from)
                            }
                            if (res.meta.last_page) {
                                setTotalPage(res.meta.last_page);
                            };
                        };
                    };
                } else {
                    setLoading(false);
                };
            } else {
                setLoading(false);
            }
            // for auto approve deposits
            if (token) {
                const response = await getMethodAuth(
                    `/setting?app_name_id=${process.env.REACT_APP_ID}`, token.token
                );
                if (response) {
                    setLoading(false);
                    if (response.status === "success") {
                        if (response.data) {
                            setAutoApprove(response.data.status);
                        } else {
                            setAutoApprove('disable');
                        }
                    }
                }
            } else {
                setLoading(false);
            }
        })();
    }, [refr, confirm, page, seenId]);

    // for auto approve deposit handler 
    const autoApproveHandler = async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("admin_auth"));
        if (token) {
            const response = await postMethod("/setting/1/update", { status: autoApprove === 'active' ? 'disable' : 'active' }, token.token)
            if (response) {
                setLoading(false);
                if (response.status === "success") {
                    setAutoApprove(autoApprove === 'active' ? 'disable' : 'active');
                }
            }
        }
    };

    const approveHandler = async (id) => {
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            width: 300,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Approve'
        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    if (token) {
                        const res = await postMethodWithoutData(`/slot/deposit-confirm/${id}/action`, token.token);
                        if (res) {
                            setLoading(false);
                            if (res.message === "Unauthenticated.") {
                                localStorage.removeItem('admin_auth');
                                navigate("/admin/login")
                            }
                            if (res.status === "error") {
                                SweetAlert.fire({
                                    icon: 'error',
                                    width: 300,
                                    title: 'Fail',
                                    text: res.message,
                                })
                            }
                            if (res.status === "success") {
                                SweetAlert.fire({
                                    title: 'Approved!',
                                    text: "You has been saved.",
                                    icon: 'success',
                                    width: 300,
                                });
                                setConfirm(!confirm);
                                dispatch(statusHandler(!value));
                            };
                            if (res.status_code === "403") {
                                alert(res.message);
                                localStorage.removeItem('admin_auth');
                                navigate("/admin/login")
                            };
                        } else {
                            setLoading(false);
                        };
                    } else {
                        setLoading(false);
                    }
                })();
            }
        });
    };

    const rejectHandler = async id => {
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        SweetAlert.fire({
            title: 'Remark!',
            icon: 'warning',
            width: 300,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
                required: true
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'Remark field is required!'
                }
            },
            showCancelButton: true,
            confirmButtonText: 'Approve',
            confirmButtonColor: '#3085d6',
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    if (token) {
                        const res = await postMethod(`/slot/deposit-reject/${id}/action`, { remark: result.value }, token.token);
                        if (res) {
                            setLoading(false);
                            if (res.message === "Unauthenticated.") {
                                localStorage.removeItem('admin_auth');
                                navigate("/admin/login")
                            };
                            if (res.status === "error") {
                                SweetAlert.fire({
                                    icon: 'error',
                                    width: 300,
                                    title: 'Fail',
                                    text: res.message,
                                })
                            }
                            if (res.status === "success") {
                                SweetAlert.fire({
                                    title: 'Approved!',
                                    text: "You has been saved.",
                                    icon: 'success',
                                    width: 300,
                                });
                                setConfirm(!confirm);
                                dispatch(statusHandler(!value));
                            };
                            if (res.status_code === "403") {
                                alert(res.message);
                                localStorage.removeItem('admin_auth');
                                navigate("/admin/login")
                            };
                        } else {
                            setLoading(false);
                        };
                    } else {
                        setLoading(false);
                    }
                })();
            }
        });
    };

    const searchSubmitHandler = () => {
        setRefr(prev => !prev);
    };

    const filterHandler = async (e) => {
        setStatus(e.target.value);
    };
    const searchHandler = e => {
        setSearchName(e.target.value);
    };
    const searchIdHandler = e => {
        setSearchId(e.target.value);
    };

    useEffect(() => {
        let total = 0
        data.length > 0 && data.map(el => {
            if (el.status === "Approved") {
                total += el.amount
            }
        });
        setDepositTotal(total);
    }, [data, confirm]);

    const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };

    return (
        <>
            <Layout>
                <Breadcrumb title="Deposit" />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className="py-3">
                                    <Row>
                                        <Col sm="6" className='px-1'>
                                            <Label className='m-0 p-0 fs-6'>Auto Approve Deposits ( On / Off )</Label>
                                        </Col>
                                        <Col sm="6" className='d-flex justify-content-end px-1'>
                                            <FormGroup switch>
                                                <Input
                                                    type="switch"
                                                    className="bg-primary"
                                                    style={{
                                                        height: "20px",
                                                        width: "35px",
                                                        userSelect: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    checked={autoApprove === 'active' ? true : false}
                                                    onClick={autoApproveHandler}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardHeader className='pt-4 pb-3'>
                                    <Row className='justify-content-end'>
                                        <Col md="4 px-1 mb-2">
                                            <Input className="form-control" onChange={searchIdHandler} type="number" placeholder="Search ID" />
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <Input className="form-control" onChange={searchHandler} type="text" placeholder="Search Name" />
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <Input type="select" name="select" className="form-control" onChange={filterHandler}>
                                                <option value="">Select Action</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Rejected">Rejected</option>
                                            </Input>
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <Input type="select" name="select" value={seenId} className="form-control" onChange={(e)=>{setSeenId(e.target.value)}}>
                                                <option value="">Seen User</option>
                                                {
                                                    seenUsers.length > 0 &&
                                                    seenUsers.map(user => 
                                                        <option key={user.id} value={user.id}>{user.name}</option>
                                                    )
                                                }
                                            </Input>
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate} />
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading ? "Loading..." : "Submit"}</button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <TableScroll>
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark' style={{ verticalAlign: 'middle' }}>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"User ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Bank"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Amount"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Trans No"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Slip Image"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Reg Phone"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Before"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"After"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Player Time"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Cashier Time"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Operator"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Remark"}</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((user, i) =>
                                                        <tr key={i} style={{ verticalAlign: 'middle' }}>
                                                            <th className='text-center' scope="row">{`${i + from}.`}</th>
                                                            <td className='text-center'>{user.user_id}</td>
                                                            <td className='text-center'>{user.user_name}</td>
                                                            <td className='text-center'>{user.payment_provider_name}</td>
                                                            <td className='text-end'>{chunk(user.amount ? user.amount : 0)}</td>
                                                            <td className='text-center'>{user.transaction_no}</td>
                                                            <td className='text-center'>{user.status === "Pending" ?
                                                                autoApprove === 'active' ? <span style={{color: 'orange'}}>Pending</span>: <div>
                                                                <span className='btn btn-success btn-sm px-2 py-1' onClick={() => approveHandler(user.id)} style={{ marginRight: '3px' }}>Accept</span>
                                                                <span className='btn btn-danger btn-sm px-2 py-1' onClick={() => rejectHandler(user.id)} style={{ marginRight: '3px' }}>Decline</span>
                                                            </div> :
                                                                <span style={{
                                                                    color: `${user.status === "Approved" ? "green" : "red"}`
                                                                }}>
                                                                    {user.status}
                                                                </span>
                                                            }
                                                            </td>
                                                            <td className='text-center'>{user.slip_image ? <div style={{ width: '20px', borderRadius: '3px', margin: 'auto' }}><ModalImage alt="Slip" small={user.slip_image} large={user.slip_image} /></div> : ""}</td>
                                                            <td className='text-center'>{user.user_phone}</td>
                                                            <td className='text-center'>{chunk(user.initial_balance ? user.initial_balance : 0)}</td>
                                                            <td className='text-center'>{chunk(user.after_balance ? user.after_balance : 0)}</td>
                                                            <td className='text-center'>{user.created_at}</td>
                                                            <td className='text-center'>{user.updated_at}</td>
                                                            <td className='text-center'>{user.receive_name ? user.receive_name : "--"}</td>
                                                            <td className='text-center'>{user.remark ? user.remark : "--"}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr className='bg-dark'>
                                                    <td colSpan={3} className="text-center text-white">Total</td>
                                                    <td className='text-end text-white'>{chunk(depositTotal)}</td>
                                                    <td colSpan={12} className='text-end text-white'></td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </TableScroll>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination
                                        setPage={setPage}
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default Deposit