import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";
import { getMethodAuth, uploadPostMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";

const CreateStreamer = () => {
    const [loading, setLoading] = useState(false);
    const [streamers, setStreamers] = useState([]);
    const [refr, setRefr] = useState(true);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [status, setStatus] = useState('');
    const [nameValidate, setNameValidate] = useState('');
    const [codeValidate, setCodeValidate] = useState('');
    const [editStreamer, setEditStreamer] = useState(false);
    const [createStreamer, setCreateStreamer] = useState(false);
    const [streamerEditId, setStreamerEditId] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            let token = JSON.parse(localStorage.getItem("admin_auth"));
            if (token) {
                let res = await getMethodAuth(
                    `/streamers`, token.token
                );
                if (res) {
                    setLoading(false);
                    if (res.message === "Unauthenticated.") {
                        localStorage.removeItem("admin_auth");
                        navigate("/admin/login");
                    }
                    if (res.status === "success") {
                        if (res.data) {
                            setStreamers(res.data);
                        }
                    }
                } else {
                    setLoading(false);
                }
            }
        })();
    }, [refr]);

    useEffect(() => {
        let result = streamers.find((streamer) => streamer.id === streamerEditId);
        if (result) {
            setName(result.name);
            setCode(result.code);
        }
    }, [streamerEditId, editStreamer]);

    const createOpenModal = () => {
        setCreateStreamer(!createStreamer);
        setName("");
        setCode("");
        setNameValidate("");
        setCodeValidate("");
    };

    const editOpenModal = () => {
        setEditStreamer(!editStreamer);
    };

    //   for create streamer handler
    const createSubmitHandler = async (e) => {
        e.preventDefault();
                setLoading(true);
        try {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("code", code);
            const token = JSON.parse(localStorage.getItem("admin_auth"));
            const response = await axios.request(
                await uploadPostMethod("/streamers", formData, token.token)
            );
            if (response.data.status === "success") {
                setLoading(false);
                setCreateStreamer(false);
                setRefr(!refr);
                setName("");
                setCode("");
            }
        } catch (error) {
            if (error.response) {
                setLoading(false);
                if (error.response.status === 403) {
                    setCreateStreamer(false);
                    SweetAlert.fire({
                        icon: "error",
                        width: 300,
                        title: <p>Fail</p>,
                        text: error.response.data.message,
                    });
                }
                if (error.response.data) {
                    if (error.response.data.message === "Unauthenticated.") {
                        localStorage.removeItem("admin_auth");
                        navigate("/admin/login");
                    }
                }
                if (error.response.data.message) {
                    if (error.response.data.message.name) {
                        setNameValidate(error.response.data.message.name);
                    }
                    if (error.response.data.message.code) {
                        setCodeValidate(error.response.data.message.code);
                    }
                }
            }
        }
    };

    //   for edit streamer handler
    const editSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (streamerEditId) {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append("name", name);
                formData.append("code", code);
                formData.append("status", status);
                const token = JSON.parse(localStorage.getItem("admin_auth"));
                const response = await axios.request(
                    await uploadPostMethod(
                        `/streamers/${streamerEditId}/update`,
                        formData,
                        token.token
                    )
                );
                if (response.data.status === "success") {
                    editOpenModal();
                    setLoading(false);
                    setRefr(!refr);
                    setName("");
                    setCode("");
                    setStatus("");
                }
            } catch (error) {
                if (error.response) {
                    setLoading(false);
                    if (error.response.status === 403) {
                        setCreateStreamer(false);
                        SweetAlert.fire({
                            icon: "error",
                            width: 300,
                            title: <p>Fail</p>,
                            text: error.response.data.message,
                        });
                    }
                    if (error.response.data) {
                        if (error.response.data.message === "Unauthenticated.") {
                            localStorage.removeItem("admin_auth");
                            navigate("/admin/login");
                        }
                    }
                    if (error.response.data.message) {
                        if (error.response.data.message.name) {
                            setNameValidate(error.response.data.message.name);
                        }
                        if (error.response.data.message.code) {
                            setCodeValidate(error.response.data.message.code);
                        }
                    }
                }
            }
        }
    };

    return (
        <>
            <Layout>
                <Breadcrumbs title="Streamers" />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className="py-4 d-flex justify-content-end">
                                    <Button
                                        color="primary"
                                        onClick={createOpenModal}
                                    >
                                        Create
                                    </Button>
                                </CardHeader>
                                <CardBody className="position-relative">
                                    <TableScroll>
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="text-center text-white" scope="col">
                                                        <b>{"No."}</b>
                                                    </th>
                                                    <th className="text-center text-white" scope="col">
                                                        <b>{"Name"}</b>
                                                    </th>
                                                    <th className="text-center text-white" scope="col">
                                                        <b>{"Code"}</b>
                                                    </th>
                                                    <th className="text-center text-white" scope="col">
                                                        <b>{"Status"}</b>
                                                    </th>
                                                    <th className="text-center text-white" scope="col">
                                                        <b>{"Reg Date & Time"}</b>
                                                    </th>
                                                    <th className="text-center text-white" scope="col">
                                                        <b>{"Action"}</b>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {streamers.length > 0 &&
                                                    streamers.map((streamer, i) => (
                                                        <tr key={i} className="align-middle">
                                                            <td className="text-center">
                                                                {`${ ++i }.`}
                                                            </td>
                                                            <td className="text-center">
                                                                {streamer.name}
                                                            </td>
                                                            <td className="text-center">
                                                                {streamer.code}
                                                            </td>
                                                            <td className="text-center">
                                                                <span className={`badge bg-${streamer.status === 'active' ? 'success' : 'danger'}`}>
                                                                {streamer.status}
                                                                </span>
                                                            </td>
                                                            <td className="text-center">
                                                                {streamer.created_at}
                                                            </td>
                                                            <td className="text-center">
                                                                <div>
                                                                    <span
                                                                        onClick={() => {
                                                                            editOpenModal();
                                                                            setStreamerEditId(streamer.id);
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-pencil"
                                                                            style={{
                                                                                width: 35,
                                                                                fontSize: 18,
                                                                                padding: 11,
                                                                                color: "rgb(40, 167, 69)",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        ></i>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </TableScroll>
                                    {loading && <Loader />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>
            {
                <Modal isOpen={createStreamer} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>Create Streamer</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameValidate("");
                                    }}
                                />
                                {nameValidate && (
                                    <div className="invalid-feedback d-block">
                                        {nameValidate}
                                    </div>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>Code</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Code"
                                    onChange={(e) => {
                                        setCode(e.target.value);
                                        setCodeValidate("");
                                    }}
                                />
                                {codeValidate && (
                                    <div className="invalid-feedback d-block">
                                        {codeValidate}
                                    </div>
                                )}
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>
                                Close
                            </Button>
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {
                <Modal isOpen={editStreamer} toggle={editOpenModal} centered>
                    <Form onSubmit={editSubmitHandler}>
                        <ModalHeader toggle={editOpenModal}>Edit Streamer</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={name}
                                    placeholder="Name"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameValidate("");
                                    }}
                                />
                                {nameValidate && (
                                    <div className="invalid-feedback d-block">
                                        {nameValidate}
                                    </div>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>Code</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={code}
                                    placeholder="Code"
                                    onChange={(e) => {
                                        setCode(e.target.value);
                                        setCodeValidate("");
                                    }}
                                />
                                {codeValidate && (
                                    <div className="invalid-feedback d-block">
                                        {codeValidate}
                                    </div>
                                )}
                            </FormGroup>
                            <Row>
                                <Col>
                                    <div className="mb-0">
                                        <Label>Streamer Status</Label>
                                        <Input type="select" name="select" className="form-control form-control-sm digits" onChange={e => {
                                            setStatus(e.target.value);
                                        }}>
                                            <option value="">Choose</option>
                                            <option value="active">Active</option>
                                            <option value="disable">Disabled</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>
                                Close
                            </Button>
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
        </>
    );
};

export default CreateStreamer;
