import React, { useEffect, useState } from 'react'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Row, Table } from 'reactstrap'
import { getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb'
import Pagination from '../utils/Pagination';
import { Link } from 'react-router-dom';
import TableScroll from '../utils/TableScroll';

const ReportStreamer = () => {
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);
  const [page,setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [from,setFrom] = useState(1);
  const navigate = useNavigate();
  const [searchId,setSearchId] = useState("");
  const [searchName,setSearchName] = useState("");
  const [searchCode,setSearchCode] = useState("");
  const [searchStatus,setSearchStatus] = useState("");
  const [refr,setRefr] = useState(true);

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/streamer-report?id=${searchId}&name=${searchName}&code=${searchCode}&status=${searchStatus}&page=${page}`, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                        setData([]);
                        setData(res.data?.data);
                    }
                    if(res.data){
                      if(res.data.from){
                          setFrom(res.data.from)
                      };
                      if(res.data.last_page){
                          setTotalPage(res.data.last_page);
                      };
                    };
                }else{
                    setData([]);
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[refr,page]);
  return (
    <>
        <Layout>
            <Breadcrumbs title="Streamers Report" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className='py-4'>
                              <Col sm="12">
                                <Form>
                                    <Row style={{justifyContent: 'flex-end'}}>
                                        <Col md="4 px-1 mb-2">
                                            <Input className="form-control" value={searchId} onChange={e => setSearchId(e.target.value)} type="number" placeholder="Search ID" />
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <Input className="form-control" value={searchName} onChange={e => setSearchName(e.target.value)} type="text" placeholder="Search name" />
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <Input className="form-control" value={searchCode} onChange={e => setSearchCode(e.target.value)} type="text" placeholder="Search code" />
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <Input type="select" name="select" className="form-control" onChange={e => setSearchStatus(e.target.value)}>
                                                <option value="">Select Status</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </Input>
                                        </Col>
                                        <Col md="4 px-1 mb-2">
                                            <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={() => setRefr(!refr)}>{loading? "Loading...":"Submit"}</button>
                                        </Col>
                                    </Row>
                                </Form>
                              </Col>
                            </CardHeader>
                            <CardBody className='position-relative'>
                                <TableScroll>
                                    <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Code</b></th>
                                                    <th className='text-center text-white' scope="col"><b>User Count</b></th>
                                                    <th className='text-center text-white' scope="col"><b>D Count</b></th>
                                                    <th className='text-center text-white' scope="col"><b>W Count</b></th>
                                                    <th className='text-center text-white' scope="col"><b>D Amount</b></th>
                                                    <th className='text-center text-white' scope="col"><b>W Amount</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Profit</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Status</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((user,i) => 
                                                    <tr key={i}>
                                                        <td className='text-center' scope="row">{i + from}.</td>
                                                        <td className='text-center' scope="row">
                                                            <Link to={`${user.id}`}>
                                                                <div className="badge bg-primary" >
                                                                   {user.name}
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td className='text-center' scope="row">{user.code}</td>
                                                        <td className='text-center' scope="row">{user.user_count}</td>
                                                        <td className='text-center' scope="row">{user.deposit_count}</td>
                                                        <td className='text-center' scope="row">{user.withdraw_count}</td>
                                                        <td className='text-end' scope="row">{user.deposit_amount?.toLocaleString('en-us')}</td>
                                                        <td className='text-end' scope="row">{user.withdraw_amount?.toLocaleString('en-us')}</td>
                                                        <td className='text-end' scope="row">{user.profit?.toLocaleString('en-us')}</td>
                                                        <td className='text-center' scope="row">
                                                            <span className={`badge badge-${user.status === 'active'? 'success':'danger'}`}>{user.status}</span>
                                                        </td>
                                                    </tr>
                                                    )
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr className='bg-dark'>
                                                    <td colSpan={3} className="text-end text-white">Total</td>
                                                    <td className='text-center text-white'>{data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.user_count), 0): 0}</td>
                                                    <td className='text-center text-white'>{data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.deposit_count), 0): 0}</td>
                                                    <td className='text-center text-white'>{data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.withdraw_count), 0): 0}</td>
                                                    <td className='text-end text-white'>{data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.deposit_amount), 0)?.toLocaleString('en-us'): 0}</td>
                                                    <td className='text-end text-white'>{data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.withdraw_amount), 0)?.toLocaleString('en-us'): 0}</td>
                                                    <td className='text-end text-white'>{data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.profit), 0)?.toLocaleString('en-us'): 0}</td>
                                                    <td className='text-end text-white'></td>
                                                </tr>
                                            </tfoot>
                                    </Table>
                                </TableScroll>
                                {
                                    loading && <Loader />
                                }
                            </CardBody>
                            {
                            totalPage > 1 &&
                            <CardFooter className='d-flex justify-content-end'>
                                <Pagination
                                setPage={setPage}
                                totalPage={totalPage}
                                setLoading={setLoading}
                                />
                            </CardFooter>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default ReportStreamer
