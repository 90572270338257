import { configureStore } from '@reduxjs/toolkit'
import depositWithdrawNotiSlice from './slices/depositWithdrawNotiSlice'
import getGameBettingWithid from './slices/getGameBettingWithid'
import authSlice from './slices/authSlice'
export const store = configureStore({
  reducer: {
      notiCount: depositWithdrawNotiSlice,
      searchBetting: getGameBettingWithid,
      auth: authSlice,
  },
})