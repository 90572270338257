import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethod, getMethodAuth } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import Pagination from '../utils/Pagination';
import DateRangeFormat from '../utils/DateRangeFormat'
import TableScroll from '../utils/TableScroll'

const TransactionHistory = () => {
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState([]);
    const navigate = useNavigate();
    const [totalPage,setTotalPage] = useState(0);
    const [from,setFrom] = useState(1);
    const [page,setPage] = useState(1);
    const [searchId,setSearchId] = useState("");
    const [providerSearch, setProviderSearch] = useState("");
    const [typeSearch,setTypeSearch] = useState("");
    const [paymentTypeSearch,setPaymentTypeSearch] = useState("");
    const [providerCode,setProviderCode] = useState([]);
    const [startDate,setStartDate] = useState("");
    const [endDate,setEndDate] = useState("");
    const [refr,setRefr] = useState(true);
    const [seenUsers, setSeenUsers] = useState([]);
    const [seenId, setSeenId] = useState('');
    const [appName, setAppName] = useState([]);
    const [searchAppName, setSearchAppName] = useState('');

    useEffect(()=>{
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        (async ()=> {
            if (token) {
                const res = await getMethodAuth('/not-user-role', token.token)
                if (res) {
                    setLoading(false);
                    if (res.status === 'success' || res.success) {
                        setSeenUsers(res?.data)
                    }
                } else {
                    setLoading(false);
                }
                let resAppName = await getMethodAuth(`/app-name`, token?.token);
                if (resAppName?.status === 'success') {
                    setAppName(resAppName.data);
                }
            }
            if(token){
                const res = await getMethodAuth(`/transaction-history?status=${typeSearch}&start_date=${startDate}&end_date=${endDate}&user_id=${searchId}&type=${paymentTypeSearch}&provider_name=${providerSearch}&app_name=${searchAppName}&page=${page}&seen_id=${seenId}`, token.token);
                if(res){
                    setLoading(false);
                    if(res.message === "Unauthenticated." || res.status_code === 403){
                        localStorage.removeItem('admin_auth');
                        navigate("/admin/login")
                    }
                    if(res.status === "success"){
                        if(res.data){
                          setData([]);
                          setData(res.data);
                        };
                    };
                    if(res.meta){
                        if(res.meta.from){
                            setFrom(res.meta.from)
                        }
                        if(res.meta.last_page){
                            setTotalPage(res.meta.last_page);
                        };
                    };
                };
            }else{
                setLoading(false);
            }
        })();
    },[refr,page,seenId]);

    useEffect(() =>{
        (async () => {
            let resProviderCode = await getMethod('/provider/codes');
            if(resProviderCode){
                setLoading(false);
                if(resProviderCode.status === "success"){
                    if(resProviderCode.data){
                        setProviderCode(resProviderCode.data);
                    };
                };
            }else{
                setLoading(false);
            };
        })();
    },[]);

     const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };

    const searchSubmitHandler = () => {
        setRefr(prev => !prev);
    };

    const providerHandler = e => {
        setProviderSearch(e.target.value);
    };

    const typeHandler = e => {
        setTypeSearch(e.target.value);
    };

    const paymentTypeHandler = e => {
        setPaymentTypeSearch(e.target.value);
    };
    const searchIdHandler = e => {
        setSearchId(e.target.value);
    };
  
  return (
    <>
        <Layout>
            <Breadcrumbs title="Transaction History" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="4 px-1 mb-2">
                                                    <Input className="form-control" onChange={searchIdHandler} type="number" placeholder="Search ID" />
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={providerHandler}>
                                                        <option value="">Select Provider</option>
                                                        {
                                                            providerCode.length > 0 &&
                                                            providerCode.map((el,i) => 
                                                              <option key={i} value={el.provider_code}>{el.provider_name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={typeHandler}>
                                                        <option value="">Select Type</option>
                                                        <option value="wallet">Wallet</option>
                                                        <option value="unit">Unit</option>
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={paymentTypeHandler}>
                                                        <option value="">Select Status</option>
                                                        <option value="withdraw">Withdraw</option>
                                                        <option value="deposit">Deposit</option>
                                                        <option value="transfer_in">Transfer In</option>
                                                        <option value="transfer_out">Transfer Out</option>
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" value={seenId} className="form-control" onChange={(e)=>{setSeenId(e.target.value)}}>
                                                        <option value="">Seen User</option>
                                                        {
                                                            seenUsers.length > 0 &&
                                                            seenUsers.map(user => 
                                                                <option key={user.id} value={user.id}>{user.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <Input type="select" name="select" className="form-control" onChange={e => setSearchAppName(e.target.value)}>
                                                        <option value="">Select App Name</option>
                                                        {
                                                            appName.length > 0 &&
                                                            appName.map((el,index) => 
                                                                <option key={`app_name_key_${index}_${el.id}`} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate}/>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading? "Loading...":"Submit"}</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <TableScroll>
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark' style={{verticalAlign: 'middle'}}>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"User ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Trans ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Type"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Bank"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Provider"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Game name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Reg Phone"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Balance"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Date"}</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                data.length > 0 &&
                                                data.map((user,i) => 
                                                    <tr key={i} style={{verticalAlign: 'middle'}}>
                                                        <th className='text-center' scope="row">{`${i+from}.`}</th>
                                                        <td className='text-center'>{user.user_id}</td>
                                                        <td className='text-center'>{user.transaction_id? user.transaction_id: "--"}</td>
                                                        <td className='text-center'>{user.status}</td>
                                                        <td className='text-center'>{user.payment_provider_name?user.payment_provider_name: '--'}</td>
                                                        <td className='text-center'>{user.provider_name?user.provider_name:"--"}</td>
                                                        <td className='text-center'>{user.game_name?user.game_name:"--"}</td>
                                                        <td className='text-center'>{user.user_name}</td>
                                                        <td className='text-center'>{user.user_phone}</td>
                                                        <td className='text-center'>{user.type}</td>
                                                        <td className='text-center'>{chunk(user.balance)}</td>
                                                        <td className='text-center'>
                                                            <span className={`badge badge-${user.action === "Confirm" ?"success":"danger"}`}>
                                                                {user.action}
                                                            </span>
                                                        </td>
                                                        <td className='text-center'>{user.created_at}</td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </TableScroll>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default TransactionHistory