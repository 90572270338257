import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import inputDebounce from '../utils/inputDebounce';
import Pagination from '../utils/Pagination';
import DateRangeFormat from '../utils/DateRangeFormat';
import TableScroll from '../utils/TableScroll';

const ReportPayment = () => {
  const [loading, setLoading] = useState(true);
  const [searchName,setSearchName] = useState("");
  const [data,setData] = useState([]);
  const [totalPage,setTotalPage] = useState(0);
  const [from,setFrom] = useState(1);
  const [page,setPage] = useState(1);
  const navigate = useNavigate();
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");
  const [refr,setRefr] = useState(true);

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('admin_auth'));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/okie/user-payment-reporting?app_name_id=${process.env.REACT_APP_ID}&name=${searchName}&start_date=${startDate && startDate}&end_date=${endDate}&type=deposit&page=${page}`, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                        if(res.data.data){
                            setData([]);
                            setData(res.data.data);
                        };
                        if(res.data.from){
                            setFrom(res.data.from)
                        }
                        if(res.data.last_page){
                            setTotalPage(res.data.last_page);
                        };
                    };
                }else{
                    setData([]);
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[refr,page]);

  const searchSubmitHandler = () => {
    setRefr(prev => !prev);
  };

  const searchHandler = e => {
    setSearchName(e.target.value);
  };

  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };
  return (
    <>
        <Layout>
            <Breadcrumbs title="Deposit Payment Report" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="4 px-1 mb-2">
                                                    <Input className="form-control" onChange={searchHandler} type="text" placeholder="Search Name" />
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <DateRangeFormat setStartDate={setStartDate} setEndDate={setEndDate}/>
                                                </Col>
                                                <Col md="4 px-1 mb-2">
                                                    <button className="w-100 btn btn-primary" disabled={loading} type='button' onClick={searchSubmitHandler}>{loading? "Loading...":"Submit"}</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <TableScroll>
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Phone</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Count</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Amount</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                    data.length > 0 &&
                                                    data.map((el,i) => 
                                                    <tr key={i}>
                                                        <th className='text-center' scope="row">{`${i + from}.`}</th>
                                                        <td className='text-center'>{el.name}</td>
                                                        <td className='text-center'>{el.phone}</td>
                                                        <td className='text-center'>{el.count}</td>
                                                        <td style={{ textAlign: 'right' }}>{el.amount? chunk(el.amount): 0}</td>
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                            {/* <tfoot>
                                                <tr className='bg-dark'>
                                                    <td colSpan={4} className="text-center text-white">Total</td>
                                                    <td className='text-end text-white'>{chunk(data.length > 0 && data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.profit),0))}</td>
                                                </tr>
                                            </tfoot> */}
                                        </Table>
                                    </TableScroll>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default ReportPayment