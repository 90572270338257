import React, { useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody ,Input, FormGroup, Button, Form, CardFooter, Label, InputGroupText} from 'reactstrap';
import Layout from './Layout';
import Loader from '../layout/loader/Loader';
import { postMethod } from '../api';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';

const ManualWithdraw = () => {
    const [loading,setLoading] = useState(false);
    const [amount,setAmount] = useState("");
    const [user,setUser] = useState("");
    const [remark,setRemark] = useState("");
    const [amountValidate,setAmountValidate] = useState("");
    const [userValidate, setUserValidate] = useState("");
    const [remarkValidate, setRemarkValidate] = useState("");
    const navigate = useNavigate();

    const registerSubmitHandler = async e => {
        e.preventDefault();
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('admin_auth'));
        const data = {
           amount,
           status: "decrease",
           user_id: user,
           remark
        };
        if(token){
            const res = await postMethod(`/user/wallet-transfer/okie-side`,data ,token.token);
            if(res){
               setLoading(false);
               if(res.message === "Unauthenticated."){
                    localStorage.removeItem('admin_auth');
                    navigate("/admin/login")
               }
               if(res.status === "success"){
                SweetAlert.fire({
                    icon: 'success',
                    width: 300,
                    title: 'Success!',
                    text: res.message
                 }).then(res => {
                    if(res.isConfirmed){
                        setAmount("");
                        setUser("");
                        setRemark("");
                    };
                 });
               }else{
                  if(res.error){
                    setUserValidate(res.message.error);
                  }
                  if(typeof res.message === "string"){
                    setAmountValidate(res.message)
                  }
                  if(res.message.amount){
                    setAmountValidate(res.message.amount[0]);
                 };
                  if(res.message.user_id){
                    setUserValidate(res.message.user_id[0]);
                  };
                  if(res.message.remark){
                    setRemarkValidate(res.message.remark[0]);
                  };
               }
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
     };
    

  return (
    <>
        <Layout>
            <Breadcrumb title="Manual Withdraw" />
            <Container fluid={true}>
                <Row>
                    <Card>
                        <Col sm="12" xl="6">
                            <Row>
                                <Col sm="12">
                                    <Form className="theme-form" onSubmit={registerSubmitHandler}>
                                        <CardBody>
                                            <FormGroup>
                                                <Label className="col-form-label pt-0" >User ID</Label>
                                                <Input className="form-control" value={user} type="number" placeholder="Enter User ID" onChange={e => {
                                                    setUser(e.target.value);
                                                    setUserValidate("");
                                                }}/>
                                                {
                                                    userValidate && <div className="invalid-feedback d-block">{userValidate}</div>
                                                }
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="col-form-label pt-0" >Amount</Label>
                                                <Input className="form-control" value={amount} type="number" placeholder="Enter Amount" onChange={e => {
                                                    setAmount(e.target.value);
                                                    setAmountValidate("");
                                                }}/>
                                                {
                                                    amountValidate && <div className="invalid-feedback d-block">{amountValidate}</div>
                                                }
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="col-form-label pt-0" >Remark</Label>
                                                <textarea className="form-control" rows={2} style={{resize: 'none'}} value={remark} type="text" placeholder="Enter Remark" onChange={e => {
                                                    setRemark(e.target.value);
                                                    setRemarkValidate("");
                                                }}/>
                                                {
                                                    remarkValidate && <div className="invalid-feedback d-block">{remarkValidate}</div>
                                                }
                                            </FormGroup>
                                        </CardBody>
                                        <CardFooter>
                                            <Button color="primary" className="me-1">Submit</Button>
                                        </CardFooter>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                        {
                            loading && <Loader />
                        }
                    </Card>
                </Row>
            </Container>  
        </Layout>
    </>
    
  )
}

export default ManualWithdraw